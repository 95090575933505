import React, { useState, useEffect, useCallback } from "react";
import styles from "./Wallet.module.scss";
import { NavLink } from "react-router-dom";

import { useWeb3Auth } from "../../services/web3auth";

import x from "../../assets/icons/group-9-copy-2.svg";
import copy from "../../assets/icons/black_copy.svg";
import usdc_ic from "../../assets/images/usdc_small.png";
import eth from "../../assets/images/eth.png";
import usdc from "../../assets/images/usdc.png";
import uparrow from "../../assets/images/uparrow.png";
import downarrow from "../../assets/images/downarrow.png";
import duck from "../../assets/images/duck.gif";
import doldole from "../../assets/images/doldole2.gif";

import left from "../../assets/icons/ic_left.png";
import right from "../../assets/icons/ic_right.png";
import profile from "../../assets/images/profile.png";
import cover from "../../assets/images/cover.png";

import { EvmPriceServiceConnection } from "@pythnetwork/pyth-evm-js";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Web3 from "web3";

const Wallet = (props) => {
  const { provider, login, logout, getUserInfo, getAccounts } = useWeb3Auth();
  const [active, setActive] = useState(false);
  const [assetType, setAssetType] = useState({
    active: false,
    type: "USDC"
  });
  const [sendAmt, setSendAmt] = useState(0);
  const [address, setAddress] = useState("");
  const [crypto, setCrypto] = useState(0);
  const [gasInfo, setGasInfo] = useState(0);
  const [price, setPrice] = useState(0);
  const [tx, setTx] = useState({
    onGoing: false,
    txHash: ""
  });
  const [balance, setBalance] = useState({
    usdc: 0,
    eth: 0
  });
  const [page, setPage] = useState(0);
  const [targetAddr, setTargetAddr] = useState("");

  const connection = new EvmPriceServiceConnection(
    "https://xc-testnet.pyth.network"
  );

  function getGasInfo(){
    const response = fetch('https://gasstation-testnet.polygon.technology/v2');
    return response.then(res => res.json());
  }

  const getGasPrice = async () => {
    const gasInfoRecent = await getGasInfo();
    if (gasInfo == 0) {
      setGasInfo(gasInfoRecent.standard.maxFee)
    }
  }

  useEffect(() => {
    setPage(0);
    setAssetType({
      active: false,
      type: "USDC"
    });
    setSendAmt(0);
    setTargetAddr("");
    setTx({
      onGoing: false,
      txHash: ""
    });
  }, [props.active])


  console.log(gasInfo)
  getGasPrice();

  const getAddress = async () => {
    const addr = await getAccounts();
    if(!addr || addr.length < 1) return;
    if(address != addr[0]) {
      setAddress(addr[0]);
    }
  }

  const checkAddress = (addr) => {
    return provider.checkAddress(addr);
  }

  const getMATICPrice = async () => {
    const priceIds = [
      "d2c2c1f2bba8e0964f9589e060c2ee97f5e19057267ac3284caef3bd50bd2cb5"
    ];
    const priceFeeds = await connection.getLatestPriceFeeds(priceIds);
    const currentPrice = Number(priceFeeds[0].getPriceNoOlderThan(60).price)/1e8;
    if(price == 0) {
      setPrice(currentPrice);
    }
  }

  console.log(price)

  const formatNumber = (num) => {
    return num.toLocaleString("en-US", { maximumFractionDigits: 6, minimumFractionDigits: 0 })
  }

  const formatGas = (num) => {
    return num.toLocaleString("en-US", { maximumFractionDigits: 8, minimumFractionDigits: 0 })
  }


  const getMATICBalance = async () => {
    const eth = await provider.getBalance();
    console.log(eth);
    if(!eth || Number(eth)/1e18 == balance.eth || Number(eth) == 0) return;
    setBalance({
      ...balance,
      eth: Number(eth)/1e18
    })
  }

  const getUSDCBalance = async () => {
    const usdc = await provider.getUSDCBalance();
    console.log(usdc);
    if(!usdc || Number(usdc)/1e6 == balance.usdc || Number(usdc) == 0) return;
    setBalance({
      ...balance,
      usdc: Number(usdc)/1e6
    })
  }

  const transferUSDC = async () => {
    if (gasInfo == 0 || !provider || sendAmt == 0 || targetAddr == "") return;
    let txRes = null
    try {
      console.log("pika")
      txRes = await provider.transferUSDC(targetAddr, sendAmt, (Number(gasInfo)*1e9).toFixed(0) );
      console.log("pika")
    } catch (e) {
      console.log("transferUSDC:fuck", e);
      setTx({
        onGoing: false,
        txHash: "failed"
      })
    }

    if(txRes != null) {
      console.log(txRes.transactionHash)
      setTx({
        onGoing: false,
        txHash: txRes.transactionHash
      })
    } else {
      setTx({
        onGoing: false,
        txHash: "failed"
      })
    }
  }

  const transfer = async () => {
    if (gasInfo == 0 || !provider || sendAmt == 0 || targetAddr == "") return;
    let txRes = ""
    try {
      txRes = await provider.transfer(targetAddr, sendAmt, (Number(gasInfo)*1e9).toFixed(0) );
    } catch (e) {
      setTx({
        onGoing: false,
        txHash: "failed"
      })
    }
    
    if(txRes != null) {
      console.log(txRes.transactionHash)
      setTx({
        onGoing: false,
        txHash: txRes.transactionHash
      })
    } else {
      setTx({
        onGoing: false,
        txHash: "failed"
      })
    }
  }

  const shorten = (address) => {
    return `${address.slice(0,5)}...${address.slice(38,42)}`
  }

  getAddress();
  if(balance.eth == 0) {
    getMATICBalance();
  }
  getMATICPrice();
  if(balance.usdc == 0) {
    getUSDCBalance();
  }

  const emptyBal = sendAmt == "" || sendAmt == 0
  const noBal =  (assetType.type=="USDC"&&Number(sendAmt)>balance.usdc) || (assetType.type=="MATIC"&&Number(sendAmt)>balance.eth)
  const noAddr = !checkAddress(targetAddr)
  const noGas = gasInfo == 0 || (assetType.type=="MATIC" && (Number(sendAmt)+gasInfo*21000/1e9) > balance.eth) || (assetType.type=="USDC" && ((gasInfo*75000/1e9) > balance.eth || Number(sendAmt) > balance.usdc) )

  if(tx.onGoing) {
    assetType.type=="MATIC"?transfer():transferUSDC();
  }


  console.log(balance.eth)
  const onChange = (e) => {
    console.log(e.target.value.length)
    setTargetAddr(e.target.value.replace(/[^0-9a-fA-FxX]/g, ''))
  }

  const onChangeSendAMt = (e) => {
    setSendAmt(e.target.value)
  }

  console.log(tx.txHash)
  if(tx.txHash != "") {
    if(tx.txHash == "completed") {
      props.setActive(false);
      setTx({
        onGoing: false,
        txHash: ""
      })
      return;
    }
    if(tx.txHash == "failed") {
      console.log("hahahahahahahaha")
      toast.error("Transaction Failed! Please try again.", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
    } else {
      toast.success((<div>Transaction Success!
        <br/><a target="_blank" href={"https://mumbai.polygonscan.com/tx/"+tx.txHash}>
          Transaction Detail</a>
        </div>), {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
    }
    setTx({
      onGoing: false,
      txHash: "completed"
    })
  }

  


  return (
    <div className={styles.container}>
      <ToastContainer
        position="bottom-right"

       />
      {tx.onGoing?(<div className={styles.dupgae}>
        <img src={duck} /> 
      </div>):(<></>)}
      <div className={styles.wrapper}>
        {   page !== 0 ? (<div className={styles.prev} onClick={(e) => setPage(page-1)}>
					<img src={right} />
				</div>):(<></>)}
        <img className={styles.x} onClick={(e) => props.setActive(false)} src={x} />
        <div className={styles.title}>{page==0?"My Wallet":"Transfer"}</div>
        {page==0?(
          <>
            <div className={styles.addr}>
              {`${address.slice(0,6)}...${address.slice(38,42)}`}
              <img src={copy} />
            </div>
            <div className={styles.usdcBal}>
              <img src={usdc} />
              {formatNumber(balance.usdc)} USDC
            </div>
            <div className={styles.sub1}>{formatNumber(balance.usdc)} USD</div>
            <div className={styles.usdcBal}>
              <img src={eth} />
              {formatNumber(balance.eth)} MATIC
            </div>
            <div className={styles.sub2}>{formatNumber(balance.eth * price)} USD</div>
            <div className={styles.row}>
              <div className={styles.header}>NFTs</div>
              <div className={styles.blueValNoLine}>13</div>
            </div>
            <div className={styles.row}>
              <div className={styles.header}>Adds Funds</div>
              <div className={styles.elem}><span className={styles.underline}>Guide</span> / <a className={styles.blueVal} href="https://ramp.alchemypay.org/"  target="_blank">Buy Crypto</a></div>
            </div>
            <div className={styles.row}>
              <div className={styles.header}>Activity</div>
              <div className={styles.underline}>Transactions</div>
            </div>
            <div className={styles.rowLast}>
              <div className={styles.header}>Export Private Key</div>
              <div className={styles.redVal}>Export</div>
            </div>
            <div className={styles.rowAsset}>
              <div className={styles.header}>Asset</div>
              <div className={styles.assetBox} onClick={(e) => setAssetType({
                ...assetType,
                active: !assetType.active
              })} >
                <div className={styles.leftSide}>
                  <img src={assetType.type=="USDC"?usdc_ic:eth} />
                  <div>{assetType.type}</div>
                </div>
                <div className={styles.rightSide}>
                  <div>{
                  assetType.type=="USDC"?formatNumber(balance.usdc):formatNumber(balance.eth) } {assetType.type}</div>
                  <img src={uparrow} style={{transform: `rotate(${assetType.active?"0deg":"180deg"})`}} />
                </div>
              </div>
            </div>
            <div className={styles.dropdown} style={assetType.active?{height: "65px"}:{height: "0px", border: "0px"}}>
              <div className={styles.assetRow1} onClick={() => setAssetType({active: false, type: "USDC"})}>
                <div className={styles.assetLeft}>
                  <img src={usdc_ic} />
                  <div>USDC</div>
                </div>
                <div>{formatNumber(balance.usdc)} USDC</div>
              </div>
              <div className={styles.assetRow2} onClick={() => setAssetType({active: false, type: "MATIC"})}>
                <div className={styles.assetLeft}>
                  <img src={eth} />
                  <div>MATIC</div>
                </div>
                <div>{formatNumber(balance.eth)} MATIC</div>
              </div>
            </div>
            <div onClick={(e) => setPage(1)} className={styles.send}>Transfer</div>
          </>
        ):page==1?(<>
          <div className={styles.addr}>
            Transfer {assetType.type}
          </div>
          <textarea className={styles.input} onInput={onChange} maxLength="42" placeholder="Enter public address (0x)" contenteditable="true" value={targetAddr}></textarea>
          <div className={styles.line}></div>
          <div className={styles.balanceLine}>
            <div className={styles.modalLineTitle}>Balance</div>
              <div className={styles.balanceBox}>
                <div className={styles.balanceLeft}>
                    <img src={assetType.type=="USDC"?usdc_ic:eth} className={styles.balanceImg} />
                    <div className={styles.balanceTicker}>{assetType.type}</div>
                </div>
                <div className={styles.balanceAmt}>{assetType.type=="USDC"?formatNumber(balance.usdc):formatNumber(balance.eth) } {assetType.type}</div>
              </div>
          </div>
          <div className={styles.balanceLine}>
            <div className={styles.modalLineTitle}>Amount</div>
              <div className={styles.balanceBox}>
                <div className={styles.balanceLeft}>
                    <img src={assetType.type=="USDC"?usdc_ic:eth} className={styles.balanceImg} />
                    <div className={styles.balanceTicker}>{assetType.type}</div>
                </div>
                <div className={styles.balanceAmt}>
                  <input className={styles.assetInput} value={sendAmt} onChange={(e) => {onChangeSendAMt(e)}} type="number"/> {assetType.type}</div>
              </div>
          </div>
          {
            noBal?
            (<div className={styles.noBal2}><span className={styles.insufficent}>Not enough balance.</span> / <a className={styles.charge}>충전하기</a></div>):
            noAddr?
            (<div className={styles.noBal2}><span className={styles.insufficent}>Please enter the correct address.</span></div>):
            emptyBal?
            (<div className={styles.noBal2}><span className={styles.insufficent}>Please enter the transfer amount.</span></div>):
            (<div className={styles.noBal2}><span className={styles.insufficent}>&nbsp;</span></div>)
          }
          <div className={styles.btnList}>
            <div className={styles.reject} onClick={(e) => {props.setActive(false)}}>Reject</div>
            <div className={noBal||noAddr||emptyBal?styles.disabled:styles.confirm} onClick={(e) => {noBal||noAddr||emptyBal?setPage(1):setPage(2)}}>Confirm</div>
          </div>
        </>
        ):(<>
          <div className={styles.minting}>Transfer</div>
          <div className={styles.tolist}>
            <div className={styles.address}>
              <img className={styles.profilePic} src={cover}/> {shorten(address)}
            </div>
            <img src={left} className={styles.left} />
            <div className={styles.address}>
              <img className={styles.profilePic} src={profile}/> {shorten(targetAddr)}
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.tableTitle}>Transfer Amount</div>
            <div className={styles.tableElem}><img src={assetType.type=="USDC"?usdc_ic:eth} className={styles.balanceImg} />
              {formatNumber(sendAmt)} {assetType.type}
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.tableTitle}>Estimated gas fee <br></br>&nbsp;</div>
            <div className={styles.tableElem}>{assetType.type=="MATIC"?formatGas(gasInfo*21000/1e9):formatGas(gasInfo*75000/1e9)} MATIC</div>
          </div>
          <div className={styles.maxgas}>최대 요금 {assetType.type=="MATIC"?formatGas(Number(sendAmt)+gasInfo*21000/1e9):sendAmt + " USDC + " + formatGas(gasInfo*75000/1e9) } MATIC</div>
          <div></div>
          {
            noGas?(
              <div className={styles.noBal}><span className={styles.insufficent}>Not enough balance.</span> / <a className={styles.charge} href="https://ramp.alchemypay.org/"  target="_blank">Buy Crypto</a></div>
            ):(<div className={styles.noBal}><span className={styles.insufficent}>&nbsp;</span></div>)
          }
          
          <div className={styles.btnList}>
            <div className={styles.reject} onClick={(e) => {props.setActive(false)}}>Reject</div>
            <div className={noGas?styles.disabled:styles.confirm} onClick={(e) => {noGas?props.setActive(true):setTx({
              onGoing: true,
              txHash: ""
            })}}>Confirm</div>
          </div>
        </>)
      }
      </div>
    </div>
  );
};

export default Wallet;
