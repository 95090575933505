import React from "react";

import { NavLink } from "react-router-dom";
import { ArtistCard } from "../../../../components/Common/Card";
import InfiniteSlider from "../../../../components/Common/InfiniteSlider";

import styles from "./Featured.module.scss";

import wani from "../../../../assets/images/wani.png";
import kumo from "../../../../assets/images/kumo.png";
import cat from "../../../../assets/images/cat.png";
import bird from "../../../../assets/images/bird.png";
import hito from "../../../../assets/images/hito.png";
import yejoon from "../../../../assets/images/yejoon.png";
import syndi from "../../../../assets/images/syndi.png";

var artists =  Array.from({ length: 1 }, () => [
	{ "eng": "Kim Ye Joon", "name": "김예준", "img": yejoon },
	{ "eng": "Comming Soon", "name": "", "img": bird },
	{ "eng": "Comming Soon", "name": "", "img": hito },
	{ "eng": "Comming Soon", "name": "", "img": kumo },
	{ "eng": "Comming Soon", "name": "", "img": wani },
	{ "eng": "Kim Ye Joon", "name": "김예준", "img": yejoon },
	{ "eng": "Comming Soon", "name": "", "img": bird },
	{ "eng": "Comming Soon", "name": "", "img": hito },
	{ "eng": "Comming Soon", "name": "", "img": kumo },
	{ "eng": "Comming Soon", "name": "", "img": wani },
	{ "eng": "Kim Ye Joon", "name": "김예준", "img": yejoon },
	{ "eng": "Comming Soon", "name": "", "img": bird },
	{ "eng": "Comming Soon", "name": "", "img": hito },
	{ "eng": "Comming Soon", "name": "", "img": kumo },
	{ "eng": "Comming Soon", "name": "", "img": wani },
	{ "eng": "Kim Ye Joon", "name": "김예준", "img": yejoon },
	{ "eng": "Comming Soon", "name": "", "img": bird },
	{ "eng": "Comming Soon", "name": "", "img": hito },
	{ "eng": "Comming Soon", "name": "", "img": kumo },
	{ "eng": "Comming Soon", "name": "", "img": wani }
]).flat();

const Featured = () => {
  return (
  	<div className={styles.wrapper}>
	  	<div className={styles.container}>
	  		<div className={styles.title}>FEATURED ARTIST</div>
			<InfiniteSlider numElem={
				document.documentElement.clientWidth / 160
			} elems={
				artists.map((artist, item) => (
					<div style={{paddingRight: "22px"}}>
					<ArtistCard name={artist.name} eng={artist.eng} src={artist.img} isMobile={true}/>
					</div>
				))
			} />
	  	</div>
  	</div>
  );
};


export default Featured;