import React, { useState, useEffect } from "react";

import { Mobile, PC } from "../../../styles/MediaQuery";
import { NavLink } from "react-router-dom";

import styles from "./NFTDrops.module.scss";

import { Timer } from "../../../components/Common/Timer";
import { RedTag, BlueTag, BlueBlackTag, WhiteTag } from "../../../components/Common/NameTag";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import { info } from "../../../services/dummy";

import pessimistic from "../../../assets/images/pessimistic.png";
import probable from "../../../assets/images/probable.png";
import optimistic from "../../../assets/images/optimistic.png";

import { createPool } from "@vercel/postgres";


const MainSlide = (props) => {
	const [active, setActive] = useState(false);

	return (
		<div className={styles.container}>
			<div className={styles.leftSide}>
				{
					active?
					(	<div className={styles.detailInfo}>
						{ (props.artist + props.title + props.type).length + 2 > 22 ?
							(<div className={styles.detailTitle}>
								<div>{`${props.artist} ${props.title} ${props.type}`}&nbsp;&nbsp;</div>
								<div>{`${props.artist} ${props.title} ${props.type}`}</div>
							</div>) : (<div className={styles.detailTitleNoLoop}>{`${props.artist} ${props.title} ${props.type}`}</div>)
						}
						<div className={styles.mintDetails}>
							<div className={styles.strongTitle}>Mint Details</div><div className={styles.dday}>D-{new Date(new Date(props.date).getTime() - new Date().getTime()).getUTCDate()-1}</div>
						</div>
						<div className={styles.mintDatas}>
							<div className={styles.dataTitle}>Collection Type</div><div className={styles.dataString}>Music {props.type}</div>
						</div>
						<div className={styles.mintDatas}>
							<div className={styles.dataTitle}>Artist</div><div className={styles.dataString}>{`${props.artist} (${props.eng})`}</div>
						</div>
						<div className={styles.mintDatas}>
							<div className={styles.dataTitle}>Royalties / Tokens</div><div className={styles.dataString}>{`${props.royalty}% (${props.period}M) / ${props.amount}개 (${props.tier} Tiers)`}</div>
						</div>
						<div className={styles.mintDatasBottom}>
							<div className={styles.dataTitle}>Price (min)</div><div className={styles.dataString}>{`${props.min_price} ${props.currency} (${props.min_royalty}% Royalty)`}</div>
						</div>
						<div className={styles.strongTitle}>Yield to Maturity ({props.period} Month)</div>
						<div className={styles.rorWrapper}>
							<div className={styles.rorElement}>
								<div className={styles.rorTitle}>
									<img src={pessimistic} />
									Pessimistic
								</div>
								<div className={styles.rorRate}>{props.pessimistic}%</div>
							</div>
							<div className={styles.rorElement}>
								<div className={styles.rorTitle}>
									<img src={probable} />
									Probable
								</div>
								<div className={styles.rorRate}>{props.probable}%</div>
							</div>
							<div className={styles.rorElementLast}>
								<div className={styles.rorTitle}>
									<img src={optimistic} />
									Optimistic
								</div>
								<div className={styles.rorRate}>{props.optimistic}%</div>
							</div>
						</div>
						</div> ):(<div></div>)
				}
				<div className={styles.album}>
					<img src={props.cover} />
				</div>
			</div>
			<div className={styles.rightSide}>
				<div className={styles.rightSub}>{`${props.artist} (${props.eng})`}</div>
				{ props.title.length > 18? 
					(<div className={styles.rightTitle}>
						<div>{props.title}&nbsp;&nbsp;</div>
						<div>{props.title}</div>
					</div>):(<div className={styles.rightTitleNoLoop}>{props.title}</div>)
				}
				<div className={styles.rightType}>{props.type}</div>
				<Timer date={props.date} />
				<div className={styles.btnList}>
					<div className={styles.viewBtn} onClick={(e) => setActive(!active)}>{active ? "Hide Details" : "Show Details"}</div>
					<NavLink to={"/invest?pid="+props.pid} className={styles.investBtn}>Invest</NavLink>
				</div>
			</div>
		</div>
	);
};

const DropsCard = (props) => {
	return (
		<div 
			onClick={(e) => props.setter(props.pid-1)}
			className={props.selected?styles.cardWrapperSelected:styles.cardWrapper} style={{ backgroundImage: `linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.2)), url('${props.img}')`}}>
			<WhiteTag className={styles.cardTitle} upText={props.type} underText={`${props.artist} (${props.eng})`} />
			<div className={styles.cardInfo}>
				<div className={styles.titleInfo}>{props.title}</div>
				<div className={styles.artistInfo}>{`${props.artist} (${props.eng})`}</div>
			</div>
		</div>
	);
};

const NFTDrops = () => {
	const [current, setCurrent] = useState(0);
	const [datum, setDatum] = useState([]);

	function formatNumber (num) {
		return new Intl.NumberFormat('ko-KR', {maximumFractionDigits: 2}).format(num);
	}

	function monthDiff(d1, d2) {
		var months;
		months = (d2.getFullYear() - d1.getFullYear()) * 12;
		months -= d1.getMonth();
		months += d2.getMonth();
		return months <= 0 ? 0 : months;
	}

	const getInfo = async () => {
		const pool = createPool({
			connectionString: process.env.REACT_APP_POSTGRES_URL,
		})
		const { rows } = await pool.sql`SELECT * from "FANFT_Products"`; 
		return rows;
	}

	useEffect(() => {
		getInfo().then((res) => {
			if (datum.length == 0) setDatum(res);
		});
	}, []);

	console.log(datum);
	console.log(current);
  return (
  	<div className={styles.superWrapper}>
  	<div className={styles.wrapper}>
  		<div className={styles.title}>Music Invest NFT Project</div>
  		<MainSlide 
  			pid={datum[current]?datum[current].pid:0} 
  			artist={datum[current]?datum[current].artist:""} 
  			eng={datum[current]?datum[current].artist_eng:""}
  			title={datum[current]?datum[current].title:""}
  			type={datum[current]?(datum[current].nfttype==0?"Music Royalty NFT":"PFP NFT"):"PFP NFT"} 
  			date={datum[current]?datum[current].mint_start:""} 
  			cover={datum[current]?datum[current].cover:""} 
  			royalty={datum[current]?formatNumber(datum[current].royalty):0} 
  			period={datum[current]?monthDiff(new Date(datum[current].term_start),new Date(datum[current].term_end)):0} 
  			amount={datum[current]?datum[current].supply:0} 
  			tier={"3"} 
  			min_price={datum[current]?formatNumber(datum[current].min_price):0} 
  			currency={"USD"} 
  			min_royalty={datum[current]?formatNumber(datum[current].min_royalty):0}
  			pessimistic={datum[current]?formatNumber(datum[current].ytm_0):0}
  			probable={datum[current]?formatNumber(datum[current].ytm_1):0}
  			optimistic={datum[current]?formatNumber(datum[current].ytm_2):0} 
  		/>
  		<div className={styles.dropsTitle}>
  			<div className={styles.dropsMain}>FANFT Drops</div>
  			<div className={styles.dropsSub}></div>
  		</div>
  		<div className={styles.swiperContainer}>
	  		<Swiper
	  			style={{overflow: "visible", width: "100%"}}
	  			onSlideChange={(swiper) => setCurrent(swiper.realIndex)}
	  			slidesPerView={4}
	  			slidesPerGroup={1}
	  			loop={false}
	  			loopAdditionalSlides={50}
	  			navigation={false}
	  			slideToClickedSlide={true}
	  			spaceBetween={46}
	  			className="dropSwiper"
	  		>
		  		{datum.map((elem) => (
		  			<SwiperSlide key={elem.pid}>
		  				<DropsCard 
		  					selected={current == elem.pid-1}
		  					artist={elem.artist} 
		  					eng={elem.artist_eng} 
		  					type={elem.nfttype==0?"Music Royalty NFT":"PFP NFT"} 
		  					title={elem.title}
		  					img={elem.cover} 
							pid={elem.pid}
							setter={setCurrent}
		  				/>
		  			</SwiperSlide>
		  		))}
	  		</Swiper>
	  	</div>
  	</div>
  	</div>
  );
};


export default NFTDrops;