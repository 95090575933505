import React, { useState } from "react";
import { Mobile, PC } from "../../../styles/MediaQuery";

import { userInfo, info } from "../../../services/dummy";

import uparrow from "../../../assets/images/uparrow.png";
import downarrow from "../../../assets/images/downarrow.png";
import cover from "../../../assets/images/cover.png"

import { WhiteTag } from "../../../components/Common/NameTag";
import styles from "./MyTicket.module.scss";

const NFTElem = () => {
    return (
        <div className={styles.elem}>
            <div className={styles.album} style={{background: `url(${cover})`, backgroundSize: "cover"}}>
                <WhiteTag upText="사용 가능 기간" underText="23. 01. 13 ~ 26. 01. 12" className={styles.tag} />
            </div>
            <div className={styles.elemTitle}>FANFT 커뮤니티 초대</div>
            <div className={styles.elemId}># 3612_FAN</div>
            <div className={styles.bottomInfo}>
                <div>
                    <div className={styles.leftBottom}>Available now</div>
                </div>
                <div className={styles.rightBottom}>교환하기</div>
            </div>
        </div>
    );
}

const MyTicket = () => {
    const [open, setOpen] = useState([false]);
	return (
		<div className={styles.wrapper}>
			<div className={styles.subtitleDiv}>
				<div className={styles.subtitleEng}>My Ticket</div>
				<div className={styles.subtitleKr}>내 티켓 리스트</div>
			</div>
            <div className={styles.barContainer}>
                <div className={styles.listWrap} onClick={(e) => {setOpen(open.map((c, i) => { if (i === 0) return !c;}))}}>
                    <div className={styles.listTitle}> 
                        <div className={styles.ltLeft}>[헤이즈] 비도오고 그래서 (Feat. 신용재) Royalty NFT</div>
                        <div className={styles.ltRight}>Tokens : 6</div>
                    </div>
                    <div className={styles.arrow}><img src={open[0]?uparrow:downarrow}/></div>
                </div>
                <div className={open[0]?styles.elemWrap:styles.elemHidden} >
                    <NFTElem />
                    <NFTElem />
                    <NFTElem />
                    <NFTElem />
                    <NFTElem />
                    <NFTElem />
                </div>
            </div>
		</div>
	);
};

export default MyTicket;