import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

import React, { useState, useRef } from "react";
import { Mobile, PC } from "../../../styles/MediaQuery";

import styles from "./MusicInfo.module.scss";

const MusicInfo = (props) => {
    const data = props.data;
    const markdown = data?data.music_info:""
	return (
        <>
            <div style={{marginBottom: 70}}>
                <div className={styles.contents}>
                    <div className={styles.title}>About music</div>
                    <div className={styles.subtitle}>Music Info</div>
                    <ReactMarkdown rehypePlugins={[rehypeRaw, remarkGfm]} className={styles.aboutText}>
                    {markdown}
                    </ReactMarkdown>
                    <div className={styles.detailTitle}>Music Details</div>
                    <div>
                        <div className={styles.detailElem}>
                            <div>Title</div>
                            <div>{data?data.title:""}</div>
                        </div>
                        <div className={styles.detailElem}>
                            <div>Genre</div>
                            <div>{data?data.genre:""}</div>
                        </div>
                        <div className={styles.detailElem}>
                            <div>Release Date</div>
                            <div>{data?data.releaseDate.toLocaleString():""}</div>
                        </div>
                        <div className={styles.detailElem}>
                            <div>ISRC</div>
                            <div>{data?data.isrc:""}</div>
                        </div>
                        <div className={styles.detailElem}>
                            <div>Lyrics by</div>
                            <div>{data?data.lyrics_by:""}</div>
                        </div>
                        <div className={styles.detailElem}>
                            <div>Composed by</div>
                            <div>{data?data.composed_by:""}</div>
                        </div>
                        <div className={styles.detailElem}>
                            <div>Produce Detail</div>
                            <div>{data?data.compose_detail:""}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MusicInfo;