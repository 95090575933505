import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import styles from "./Indicators.module.scss";


const Indicators = () => {
  return (
    <>
        <div className={styles.liner}> </div>
        <div className={styles.container}>
            <div className={styles.indicatorElemWithBorderLeft}>
                <div className={styles.elemTitle}>FANFT Project Summary</div>
                <div className={styles.elemSub}>Key Indicators</div>
            </div>
            <div className={styles.indicatorElemWithBorder}>
                <div className={styles.elemHeading}>Community Members</div>
                <div className={styles.elemIndi}>150+</div>
                <div className={styles.elemComm}>Since July 2023</div>
            </div>
            <div className={styles.indicatorElemWithBorder}>
                <div className={styles.elemHeading}>Project Invested</div>
                <div className={styles.elemIndi} style={{color: "#3400FF"}}>12+</div>
                <div className={styles.elemComm}>With 20+ Artists </div>
            </div>
            <div className={styles.indicatorElem}>
                <div className={styles.elemHeading}>Total Fund Raised</div>
                <div className={styles.elemIndi}><strong>3K+ USD</strong></div>
                <div className={styles.elemComm}>From 100+ Minting</div>
            </div>
        </div>   
    </>
  );
};

export default Indicators;