import React, { useState } from "react";
import { Mobile, PC } from "../../../styles/MediaQuery";

import { useWeb3Auth } from "../../../services/web3auth";
import { RedTag, WhiteTag } from "../../../components/Common/NameTag";
import { userInfo, info } from "../../../services/dummy";
import over from "../../../assets/images/over.png";

import uparrow from "../../../assets/images/uparrow.png";
import downarrow from "../../../assets/images/downarrow.png";
import claimall from "../../../assets/images/claimall.png";

import ic_melon from "../../../assets/icons/ic_melon.png";
import ic_spotify from "../../../assets/icons/ic_spotify.png";
import ic_ytmusic from "../../../assets/icons/ic_ytmusic.svg";
import ic_push from "../../../assets/icons/ic_push.svg";


import styles from "./Portfolio.module.scss";

const formatDate = (date) => {
	return date.getFullYear() + ". " + date.getMonth() + ". " + date.getDate()
}

const Collection = (props) => {
	return (
		<div className={styles.cardWrapper}>
			<div className={styles.collectCard}>
				{props.available==0?(<RedTag className={styles.nameTag} upText={props.type} underText="Sold Out" />):
				(<WhiteTag className={styles.nameTag} upText={props.type} underText={`${props.available} / ${props.total} Available NFTs`} />)}
				<div className={styles.coverWrapper}>
					<img src={props.cover} className={styles.collectionCover}/>
				</div>
				<div className={styles.collectDetail}>
					<div className={styles.edition}>{`${props.artist} (${props.eng})`}</div>
					{
						(`${props.title} ${props.type}`).length < 17?
							<div className={styles.project}>{`${props.title} ${props.type}`}</div>:
							<div className={styles.projectLoop}>
								<div>{`${props.title} ${props.type}`} &nbsp;</div>
								<div>{`${props.title} ${props.type}`}</div>
							</div>
					}
					<div className={styles.underPart}>
						<img src={ic_ytmusic} className={styles.icon} />
						<img src={ic_spotify} className={styles.icon} />
						<img src={ic_melon} className={styles.icon} />
						<img src={ic_push} className={styles.icon} />
					</div>
				</div>
			</div>
		</div>
	);
};

const PortfolioElem = (props) => {
	const invest = props.invest;
	const data = info[invest.pid-1];
	const [active, setActive] = useState(false);
	return (
		<div className={styles.royaltyBox} style={active?{ height: "509px" }:{ height: "104px" } }>
			<div className={styles.royaltyTitle} onClick={(e) => setActive(!active)}>
				<div className={styles.titleLeftWrapper}>
					<div className={styles.royaltyTitleText}>{`[${data.artist}] ${data.title} ${data.type}`}</div>
					{active?(<></>):(<div className={styles.royaltyTerm}>Tokens : 12,&nbsp;</div>)}
					{active?(<></>):(<div className={styles.royaltyRoi}>Amount : <span className={styles.red}>265.8 USDC</span></div>)}
				</div>
				<div className={styles.titleRight}>
					{active?(<></>):(<div className={styles.claimBox}>
						<div className={styles.claimNumBox}>{invest.available_claim}</div>
						Claim Royalties
					</div>)}
					<img src={active?uparrow:downarrow} className={styles.arrows} />
				</div>
			</div>
			<div className={styles.downRow}>
				<div className={styles.table2}>
					<Collection type="Royalty NFT" 
						available={10} total={500} 
						artist="김예준" eng="Yejoon Kim"
						title="Over" cover={over}
					/>
				</div>
				<div className={styles.royaltyTable}>
					<div className={styles.rowTitle}>Investment Details</div>
					<div className={styles.row}>
						<div className={styles.header}>Vesting period</div>
						<div className={styles.tableVal}>{formatDate(new Date(invest.start))} ~ {formatDate(new Date(invest.end))}</div>
					</div>
					<div className={styles.row}>
						<div className={styles.header}>Tokens</div>
						<div className={styles.tableVal}>{invest.amt.map((amt, idx) => `${data.tier[idx].name} ${amt}${idx<(invest.amt.length-1)?", ":""}`)}</div>
					</div>
					<div className={styles.row}>
						<div className={styles.header}>Ownership</div>
						<div className={styles.tableVal}>{invest.royalty} %</div>
					</div>
					<div className={styles.row}>
						<div className={styles.header}>Rounds</div>
						<div className={styles.tableVal}>{`${invest.current_claim} / ${invest.total_claim}`}</div>
					</div>
					<div className={styles.row}>
						<div className={styles.header}>Claimable Amount</div>
						<div className={styles.tableValRed}>+ 132.9 USDC</div>
					</div>
					<div className={styles.rowLast}>
						<div className={styles.header}>Claimed Amount</div>
						<div className={styles.tableVal}>123.9 USDC</div>
					</div>
					<div className={styles.emRow}>
						<div className={styles.emTitle}>Total Claims</div>
						<div className={styles.emRor}><strong>268.3 USDC</strong></div>
					</div>
				</div>
				<div className={styles.table3}>
				<div className={styles.bgInv}><div className={styles.claimBtnInv}>Play Music</div></div>
					<div className={styles.bg}><div className={styles.claimBtn} onClick={(e) => {props.setActive(true)}}>
						<div className={styles.claimNumBox}>{invest.available_claim}</div>
						Claim Royalties
					</div></div>
					<div className={styles.bg}><div className={styles.claimBtn}>Royalty Details</div></div>
					<div className={styles.bgLast}><div className={styles.claimBtn}>More</div></div>
				</div>
			</div>
		</div>
	)
}

const Portfolio = (props) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.subtitleDiv}>
				<div className={styles.subtitleEng}>My portfolio</div>
			</div>
			<div className={styles.box}>
				<div className={styles.boxElem}>
					<div className={styles.boxElemTitle}># of Invested Projects</div>
					<div className={styles.boxElemValueBlue}>0</div>
					<div className={styles.boxElemSub}>Invested</div>
				</div>
				<div className={styles.line}>&nbsp;</div>
				<div className={styles.boxElem}>
					<div className={styles.boxElemTitle}>Total Claimed</div>
					<div className={styles.boxElemValue}><strong>{new Intl.NumberFormat('ko-KR').format(0)} USD</strong></div>
					<div className={styles.boxElemSub}>Claimed Royalty</div>
				</div>
				<div className={styles.line}>&nbsp;</div>
				<div className={styles.boxElem}>
					<div className={styles.boxElemTitle}>Claimable Amount</div>
					<div className={styles.boxElemValueRed}>+ {new Intl.NumberFormat('ko-KR').format(0)} USD</div>
					<div className={styles.boxElemSub}>Claimable Royalty</div>
				</div>
				<div className={styles.line}>&nbsp;</div>
				<div className={styles.boxElem}>
					<div className={styles.boxElemTitleUnderlined}><strong>Claim Royalties</strong></div>
					<div className={styles.btnBorder}><div className={styles.claimAllBtn}><img src={claimall} /> Claim All</div></div>
				</div>
				
				
			</div>
			{
				// userInfo.invest.map((elem, idx) => (
				// 	<PortfolioElem invest={elem} key={idx} setActive={props.setActive} />
				// ))
			}
		</div>
	);
};

export default Portfolio;