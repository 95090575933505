import { ADAPTER_EVENTS, SafeEventEmitterProvider, CHAIN_NAMESPACES, WALLET_ADAPTERS } from "@web3auth/base";
import { Web3Auth } from "@web3auth/web3auth";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { createContext, FunctionComponent, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { getWalletProvider } from "./walletProvider";


import handsIcon from "../assets/icons/handsIcon.png";


export const Web3AuthContext = createContext({
  web3Auth: null,
  provider: null,
  isLoading: false,
  user: null,
  chain: "",
  login: async () => {},
  logout: async () => {},
  getUserInfo: async () => {},
  signMessage: async () => {},
  getAccounts: async () => {},
  getBalance: async () => {},
  getUSDCBalance: async () => {},
  transfer: async (to, amount, gasprice) => {},
  transferUSDC: async (to, amount, gasprice) => {},
  checkAddress: (address) => {},
  signTransaction: async () => {},
  signAndSendTransaction: async () => {},
});

export function useWeb3Auth() {
  return useContext(Web3AuthContext);
}


export const Web3AuthProvider = ({ children, web3AuthNetwork, chain }) => {
  const [web3Auth, setWeb3Auth] = useState(null);
  const [provider, setProvider] = useState(null);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const setWalletProvider = useCallback(
    (web3authProvider) => {
      const walletProvider = getWalletProvider(chain, web3authProvider, uiConsole);
      setProvider(walletProvider);
    },
    [chain]
  );

  useEffect(() => {
    const subscribeAuthEvents = (web3auth) => {
      // Can subscribe to all ADAPTER_EVENTS and LOGIN_MODAL_EVENTS
      web3auth.on(ADAPTER_EVENTS.CONNECTED, (data) => {
        console.log("Yeah!, you are successfully logged in", data);
        setUser(data);
        setWalletProvider(web3auth.provider);
      });

      web3auth.on(ADAPTER_EVENTS.CONNECTING, () => {
        console.log("connecting");
      });

      web3auth.on(ADAPTER_EVENTS.DISCONNECTED, () => {
        console.log("disconnected");
        setUser(null);
      });

      web3auth.on(ADAPTER_EVENTS.ERRORED, (error) => {
        console.error("some error or user has cancelled login request", error);
      });
    };

    const currentChainConfig = {
      chainNamespace: CHAIN_NAMESPACES.EIP155,
      rpcTarget: "https://polygon-mumbai-bor.publicnode.com",
      blockExplorer: "https://mumbai.polygonscan.com/",
      chainId: "0x13881",
      displayName: "Polygon Mumbai Testnet",
      ticker: "matic",
      tickerName: "Matic",
    }

    async function init() {
      try {
        setIsLoading(true);
        const clientId = "BPRKToomJuYGuQe0da3-NW0ecb2UCmlJZiBxaeqUi3Z_BsgA57TjTYkTF01Ub9kLeCjzM_llVbczaGPInj9Eu3A";
        const web3AuthInstance = new Web3Auth({
          chainConfig: currentChainConfig,
          uiConfig: {
            theme: "light",
            loginMethodsOrder: ["google", "facebook", "twitter", "apple","kakao"],
            appLogo: handsIcon, // Your App Logo Here
          },
          // get your client id from https://dashboard.web3auth.io
          clientId,
        });
        const adapter = new OpenloginAdapter({ adapterSettings: { network: web3AuthNetwork, clientId } });
        web3AuthInstance.configureAdapter(adapter);
        subscribeAuthEvents(web3AuthInstance);
        setWeb3Auth(web3AuthInstance);
        await web3AuthInstance.initModal({
          modalConfig: {
            [WALLET_ADAPTERS.TORUS_EVM]: {
              showOnModal: false
            },
            [WALLET_ADAPTERS.OPENLOGIN]: {
              loginMethods: {
                google: {
                  name: "Google",
                  showOnModal: true
                },
                facebook: {
                  name: "Facebook",
                  showOnModal: true
                },
                reddit: {
                  name: "Reddit",
                  showOnModal: false
                },
                discord: {
                  name: "Discord",
                  showOnModal: false
                },
                twitch: {
                  name: "Twitch",
                  showOnModal: false
                },
                apple: {
                  name: "Apple",
                  showOnModal: true
                },
                line: {
                  name: "Line",
                  showOnModal: false
                },
                github: {
                  name: "Github",
                  showOnModal: false
                },
                kakao: {
                  name: "Kakao",
                  showOnModal: true
                },
                linkedin: {
                  name: "Linkedin",
                  showOnModal: false
                },
                twitter: {
                  name: "Twitter",
                  showOnModal: true
                },
                weibo: {
                  name: "Weibo",
                  showOnModal: false
                },
                wechat: {
                  name: "Wechat",
                  showOnModal: false
                },
                email_passwordless: {
                  name: "Email_passwordless",
                  showOnModal: false
                }
              }
            }
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
    init();
  }, [chain, web3AuthNetwork, setWalletProvider]);

  const login = async () => {
    if (!web3Auth) {
      console.log("web3auth not initialized yet");
      uiConsole("web3auth not initialized yet");
      return;
    }
    const localProvider = await web3Auth.connect();
    setWalletProvider(localProvider);
  };

  const logout = async () => {
    if (!web3Auth) {
      console.log("web3auth not initialized yet");
      uiConsole("web3auth not initialized yet");
      return;
    }
    await web3Auth.logout();
    setProvider(null);
    window.location.reload();
  };

  const getUserInfo = async () => {
    if (!web3Auth) {
      console.log("web3auth not initialized yet");
      uiConsole("web3auth not initialized yet");
      return;
    }
    const user = await web3Auth.getUserInfo();
    return user;
  };

  const getAccounts = async () => {
    if (!provider) {
      console.log("provider not initialized yet");
      uiConsole("provider not initialized yet");
      return;
    }
    return await provider.getAccounts();
  };

  const getBalance = async () => {
    if (!provider) {
      console.log("provider not initialized yet");
      uiConsole("provider not initialized yet");
      return;
    }
    await provider.getBalance();
  };

  const getUSDCBalance = async () => {
    if (!provider) {
      console.log("provider not initialized yet");
      uiConsole("provider not initialized yet");
      return;
    }
    await provider.getUSDCBalance();
  };

  const checkAddress = (address) => {
    if (!provider) {
      console.log("provider not initialized yet");
      uiConsole("provider not initialized yet");
      return;
    }
    provider.checkAddress(address);
  };

  const transfer = async (to, amount, gasprice) => {
    if (!provider) {
      console.log("provider not initialized yet");
      uiConsole("provider not initialized yet");
      return;
    }
    await provider.transfer(to, amount, gasprice);
  };

  const transferUSDC = async (to, amount, gasprice) => {
    if (!provider) {
      console.log("provider not initialized yet");
      uiConsole("provider not initialized yet");
      return;
    }
    await provider.transferUSDC(to, amount, gasprice);
  };

  const signMessage = async () => {
    if (!provider) {
      console.log("provider not initialized yet");
      uiConsole("provider not initialized yet");
      return;
    }
    await provider.signMessage();
  };

  const signTransaction = async () => {
    if (!provider) {
      console.log("provider not initialized yet");
      uiConsole("provider not initialized yet");
      return;
    }
    await provider.signTransaction();
  };

  const signAndSendTransaction = async () => {
    if (!provider) {
      console.log("provider not initialized yet");
      uiConsole("provider not initialized yet");
      return;
    }
    await provider.signAndSendTransaction();
  };

  const uiConsole = (...args) => {
    console.log(JSON.stringify(args || {}, null, 2));
  };

  const contextProvider = {
    web3Auth,
    chain,
    provider,
    user,
    isLoading,
    login,
    logout,
    getUserInfo,
    getAccounts,
    getBalance,
    signMessage,
    signTransaction,
    signAndSendTransaction,
  };
  return <Web3AuthContext.Provider value={contextProvider}>{children}</Web3AuthContext.Provider>;
};