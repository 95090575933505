import React, { useState, useEffect, useCallback } from "react";
import styles from "./Header.module.scss";

import { Mobile, PC } from "../../styles/MediaQuery";
import logo from "../../assets/images/logo.png";
import plus from "../../assets/images/ic_plus.svg";
import { NavLink } from "react-router-dom";
import profile from "../../assets/images/profile.png";
import chevronDownIcon from "../../assets/icons/chevron-down-18.svg";
import flagIcon from "../../assets/icons/flag-14@3x.png";
import checkIcon from "../../assets/icons/ic-check-gy-24-copy-3.svg";
import walletIcon from "../../assets/images/wallet_black.png";

import triangle from "../../assets/images/triangle.svg";
import triangleDown from "../../assets/images/triangle_down.svg";
import account from "../../assets/images/account.png";
import tx from "../../assets/images/tx.png";
import wallet from "../../assets/images/wallet.png";
import logoutBtn from "../../assets/images/logout.png";

import Wallet from "../Wallet/Wallet";
import { useWeb3Auth } from "../../services/web3auth";
import { useLocation } from 'react-router-dom';
import CommunityModal from "../CommunityModal/CommunityModal";

const Header = () => {
  const { provider, login, logout, getUserInfo, getAccounts } = useWeb3Auth();
  const [active, setActive] = useState(false);
  const [activeWallet, setActiveWallet] = useState(false);
  const [activeComm, setActiveComm] = useState(false);
  const [headerTop, setHeaderTop] = useState(0);
  const [mobile, setMobile] = useState(false);
  const [address, setAddress] = useState("");
  const location = useLocation();


  const onScroll = useCallback(event => {
    const { pageYOffset, scrollY } = window;
    setHeaderTop(scrollY)
  }, []);

  const getAddress = async () => {
    const addr = await getAccounts();
    if(!addr || addr.length < 1) return;
    if(address != addr[0]) {
      setAddress(addr[0]);
    }
  }

  getAddress();

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 1080) {
        setMobile(true);
      }
      else {
        setMobile(false);
      }
    }
    handleResize()

    document.addEventListener("scroll", onScroll, { passive: true });
    window.addEventListener('resize', handleResize)
    return () => { 
      document.removeEventListener("scroll", onScroll);
      window.removeEventListener("resize", handleResize);
    }
  }, []);

  return !location.pathname.includes("mobile")?(
    <div className={styles.container} 
      style={(headerTop>0 || location.pathname=="/guide")?{borderBottom: "#000 1px solid", backgroundColor: "#fff"}:{}}
    >
      <div className={styles.mobileAlertBg} style={{display:mobile?"flex":"none"}}>
        <div className={styles.mobileAlertModal}>
          <div>This page is currently not supported on mobile devices. <br />Please use devices with a resolution of 1080px or higher</div>
          <NavLink className={styles.mobileBtn} to="/mobile">Go to mobile page</NavLink>
         </div>
      </div>
      <div className={styles.menuContainer}>
        <div className={styles.logo}>
          <NavLink to="/">
            <img src={logo} alt="logo" />
          </NavLink>
        </div>
          <div className={styles.menus}>
            <NavLink className={styles.menuItem} to="/">Home</NavLink>
            <NavLink className={styles.menuItem} to="/collection">Invest</NavLink>
            <a className={styles.menuItem} href="https://docs.fanft.land/" target="_blank">User Guides</a>
            <a className={styles.menuItem} href="https://linktr.ee/fanft.land" target="_blank">Community</a>
          </div>
      </div>
      <div className={styles.contents}>
        { provider?
          (<div className={styles.profile}>
            <div className={styles.profileWrapper} onClick={(e) => {setActive(!active)}}>
              <img src={profile} className={styles.profileImg} />
              {address.slice(0,6) + "..." + address.slice(38)}
              {active?(<img src={triangleDown} className={styles.triangle}/>):(<img src={triangle} className={styles.triangle}/>)}
            </div>
            {active?(<div className={styles.profileMenu}>
              <NavLink className={styles.menuElem} to="/mypage" onClick={(e) => setActive(false)}><img src={account}  /> My Page</NavLink>
              <div className={styles.menuElem}><img src={tx} /> Transactions</div>
              <div onClick={(e) => setActiveWallet(true)}  className={styles.menuElem}><img src={wallet} /> Wallets</div>
              <div className={styles.lineMenu}></div>
              <div onClick={logout} className={styles.menuElemBottom}><img src={logoutBtn} /> Logout</div>
            </div>):(<></>)}
          </div>):
          (<div className={styles.login} onClick={login}>Sign in <img src={walletIcon} className={styles.loginImg} /></div>)
        }
      </div>
      { activeWallet?(<Wallet setActive={setActiveWallet}/>):(<></>) }
      { activeComm?(<CommunityModal setActive={setActiveComm}/>):(<></>) }
    </div>
  ):(
  <div className={styles.mobileContainer}>
    <div className={styles.mobileHeaderContainer}>
      <NavLink to="/mobile/main">
        <img className={styles.mobilelogo} src={logo} alt="logo" />
      </NavLink>
      <img className={styles.plusBtn} src={plus} alt="plus" />
    </div>
  </div>);
};

export default Header;
