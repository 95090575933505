import React, { useState, useEffect } from "react";
import { Mobile, PC } from "../../styles/MediaQuery";

import FlowBanner from "../main/FlowBanner/FlowBanner";
import Footer from "../main/Footer/Footer";
import NFTDrops from "./NFTDrops/NFTDrops";
import TopCollection from "./TopCollection/TopCollection";

const Collectionpage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <>
      <NFTDrops />
      <TopCollection /> 
      <FlowBanner />
      <Footer />
    </>
  );
};

export default Collectionpage;