import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import React, { useState } from 'react';
import Slider from 'react-slick';

const InfiniteSlider = (props) => {
    const [settings] = useState({
      dots: false,
      infinite: true,
      arrows: false,
      swipe: false,
      focusOnSelect: false,
      slidesToShow: props.numElem,
      slidesToScroll: 1,
      cssEase:'linear',
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
      cssEase: 'linear',
    });
    // Your component logic
    return (
      <div className={props.className}>
        <Slider {...settings}>
          {props.elems}
        </Slider>
      </div>
    );
};

export default InfiniteSlider;