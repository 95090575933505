import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

import React, { useState, useEffect } from "react";
import { Mobile, PC } from "../../../styles/MediaQuery";
import about from "../../../assets/images/about.png";

import styles from "./ProjectDetail.module.scss";

const markdown = `
🎵 Music Revenue NFT Project 🎵

We present a pioneering NFT project aimed at revolutionizing the music industry. By tokenizing music revenues into NFTs, we directly connect with Web3 users. By purchasing our NFTs, investors can directly support our musical endeavors. The funds raised through this venture will be directly allocated to music marketing and the production of music videos.

Through this project,

Music creators and fans can form a closer bond.
Experience the benefits of Web3 technology firsthand and lead a new paradigm in the music industry.
Investors will share in the value of the music's success.
Join this innovative musical venture now, and together, let's shape the future of music!
`


const timelineText = "The music track will be released on September 1st, 2023, at 8:00 PM. The distribution contract for this track with the music distributor FANFT was completed on August 1st, 2023. You can check the track on music streaming platforms such as YouTube Music and Spotify on the release date."

const ProjectDetail = (props) => {
    const data = props.data;
    const timeline = props.timeline;
    const [more, setMore] = useState(false);
    const [aboutMore, setAboutMore] = useState(false);

    const origText = data?data.project_about.trim():""

    const moreThen4Line = origText.split('\n').length > 4
    const moreThan336 = origText.length > 336

    const aboutText = !(moreThen4Line || moreThan336)?origText:(
        aboutMore?origText:(
            moreThen4Line?origText.split('\n').slice(0, 4).join('\n') + ` ... `:origText.slice(0, 336) + ` ... `
        )
    )

    if(aboutMore) {
        document.getElementById("read-more").remove()
    }

    console.log(timeline)

    useEffect(() => {
        if(aboutMore) return;
        // Get all of the <p> elements
        const pElements = document.getElementsByClassName(styles.aboutText)[0].getElementsByTagName("p");
    
        // Create a button
        const button = document.createElement("a");
        button.innerHTML = " Read More";
        button.id = "read-more";
        button.className = styles.readMore;
        button.onclick = () => {
            setAboutMore(true)
        };
    
        const refChild = pElements[pElements.length - 1];
        if (refChild) {
            refChild.style.display = "inline"
        }
        // Insert the button before the next sibling of the second <p> tag
        refChild && refChild.parentNode.insertBefore(button, refChild.nextSibling);
      }, [aboutMore]);

	return (
        <>
            <div>
                {/* <div className={styles.contents} style={more?{}:{ height: 1800, overflow: "hidden" }}> */}
                <div className={styles.contents}>
                    <div className={styles.title}>Project Details</div>
                    <div className={styles.about}>Project About</div>
                    <ReactMarkdown children={aboutText} rehypePlugins={[rehypeRaw, remarkGfm]} className={styles.aboutText} />
                    <div className={styles.roadmapTitle}>Project Roadmap</div>
                    <div className={styles.roadmapContainter}>
                        {timeline?timeline.map((elem) => {
                            return (
                                <div className={styles.roadmapElem} key={elem.tlid}>
                                    <div className={styles.nemo}>&nbsp;&nbsp;&nbsp;</div>
                                    <div className={styles.roadmapContent}>
                                        <div className={styles.roadmapElemTitle}>{elem.title}</div>
                                        <div className={styles.roadmapElemLine}>
                                            <div className={styles.roadmapElemSubtitle}>Date</div>
                                            <div className={styles.roadmapElemText}>{elem.date.toString()}</div>
                                        </div>
                                        <div className={styles.roadmapElemLine}>
                                            <div className={styles.roadmapElemSubtitle}>Content</div>
                                            <ReactMarkdown children={elem.content} rehypePlugins={[rehypeRaw, remarkGfm]} className={styles.roadmapElemText} />
                                        </div>
                                    </div>
                                </div>
                            )
                        }):(<></>)}
                    </div>
                    {/* <div className={styles.aboutTitle}>Details</div>
                    <div className={more?styles.aboutMore:styles.aboutHidden}>
                        {!more?(<div className={styles.overlay}></div>):(<></>)}
                        <img src={about} className={styles.aboutImg} />
                        {more?(<></>):(<div onClick={() => setMore(true)} className={styles.moreBtn}>View More</div>)}
                    </div> */}
                </div>
            </div>
        </>
    );
};

export default ProjectDetail;