import React, { useState, useEffect, useCallback } from "react";
import styles from "./CommunityModal.module.scss";
import { NavLink } from "react-router-dom";

import x from "../../assets/icons/group-9-copy-2.svg";

import kakao from "../../assets/icons/ic_kakao.png";
import twitter from "../../assets/icons/ic_twitterw.png";
import insta from "../../assets/icons/ic_insta.png";
import discord from "../../assets/icons/ic_discord.png";


const CommunityModal = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <img className={styles.x} onClick={(e) => props.setActive(false)} src={x} />
        <div className={styles.title}>Join FANFT Community!</div>
        <div className={styles.btnList}>
            <div className={styles.blackBtn}>
                <img src={kakao} />
                카카오 오픈채팅 참여
            </div>
            <div className={styles.blackBtn}>
                <img src={discord} />
                공식 디스코드 입장
            </div>
            <div className={styles.whiteBtn}>
                <img src={insta} />
                인스타그램 방문
            </div>
            <div className={styles.whiteBtn}>
                <img src={twitter} />
                트위터 팔로우
            </div>
        </div>
      </div>
    </div>
  );
}

export default CommunityModal;