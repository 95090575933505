import React, { useState } from "react";
import styles from "./NameTag.module.scss";
import { Mobile, PC } from "../../styles/MediaQuery";

const RedTag = (props) => { return (
		<div className={props.className} style={{transform: "rotate(-4deg)"}}>
			<div className={styles.cardType}>{props.upText}</div>
			<div className={styles.cardArtistRed}>{props.underText}</div>
		</div>
	); };
const BlueTag = (props) => { return (
		<div className={props.className} style={{transform: "rotate(-4deg)"}}>
			<div className={styles.cardTypeBlue}>{props.upText}</div>
			<div className={styles.cardArtist}>{props.underText}</div>
		</div>
	); };
const BlueBlackTag = (props) => { return (
		<div className={props.className} style={{transform: "rotate(-4deg)"}}>
			<div className={styles.cardTypeBlue}>{props.upText}</div>
			<div className={styles.cardArtistBlack}>{props.underText}</div>
		</div>
	); };
const WhiteTag = (props) => { return (
		<div className={props.className} style={{transform: "rotate(-4deg)"}}>
			<div className={styles.cardType}>{props.upText}</div>
			<div className={styles.cardArtist}>{props.underText}</div>
		</div>
	); };

export { RedTag, BlueTag, BlueBlackTag, WhiteTag };