import React, { useState } from "react";
import { Mobile, PC } from "../../../styles/MediaQuery";

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import styles from "./Simulator.module.scss";
import { benefits } from "../../../services/dummy";

import usdc_ic from "../../../assets/images/usdc_small.png";
import pessimistic from "../../../assets/images/pessimistic.png";
import probable from "../../../assets/images/probable.png";
import optimistic from "../../../assets/images/optimistic.png";

const preImg = [pessimistic,probable,optimistic]
const preStr = ["pessimistic","probable","optimistic"]

const Simulator = (props) => {
	const active = props.active;
	const setActive = props.setActive;
	const data = props.data;
	const tier = props.tier;
	const [predict, setPredict] = useState(1);
	const [preamt, setPreamt] = useState(1);

	function monthDiff(d1, d2) {
		var months;
		months = (d2.getFullYear() - d1.getFullYear()) * 12;
		months -= d1.getMonth();
		months += d2.getMonth();
		return months <= 0 ? 0 : months;
	}

	if(tier && preamt > tier[active].quantity) {
		setPreamt(1);
	}

	const onSliderChange = (value) => {
		setPredict(value);
	};
	if(predict == "0") setPredict(1);
	if(preamt == "0") setPreamt(1);

	const rewardTier = [
		{name: "Normal"},
		{name: "FAN"},
		{name: "VIP"},
	];

	return (
		<div className={styles.container}>
			<div className={styles.titleContainer}>
				<div className={styles.title}>Invest Simulator</div>
				{/* <div className={styles.subtitle}>투자 시뮬레이터</div> */}
			</div>
			<div className={styles.typeList}>
				<div className={styles.typeTitle}>Types</div>
				{
					tier?tier.map((tierElem, idx) => 
						(<div className={idx==props.active?styles.typeActive:styles.typeElem} key={idx} onClick={(e) => props.setActive(idx)}>{rewardTier[tierElem.tier%3].name}</div>)
					):(<></>)
				}
			</div>
			<div className={styles.slideWrapper}>
				<div className={styles.leftSide}>
					<div className={styles["ballonWrapper"+predict]}>
						<div className={styles["ballon"+predict]}>
							<div className={styles.upper}>
								<img className={styles.upperImg} src={preImg[predict-1]} />
								{preStr[predict-1]}
							</div>
							<div className={styles.downer}>{new Intl.NumberFormat('ko-KR').format(data?data["ytm_"+(predict-1)]:0)}%</div>
						</div>
					</div>

					<Slider
						min={0} max={3} step={1}
						value={predict}
						trackStyle={{ backgroundColor: '#151516', height: 8, borderRadius: 0 }}
						handleStyle={{
							boxShadow: "0 2px 4px 0 rgba(140, 140, 140, 0.3), 0 -2px 4px 0 rgba(140, 140, 140, 0.2)",
							height: 20,
							width: 20,
							marginLeft: 0,
							marginTop: -6,
							border: 0,
							opacity: 1,
							backgroundColor: '#151516',
						}}
						onChange={(value) => setPredict(value)}
						railStyle={{ backgroundColor: '#e6e6e6', height: 8, borderRadius: 0 }}
					/>
					<div className={styles.sub1}>Rate of return</div>
					<div className={styles.tokens}>Tokens</div>
					<Slider
						min={0} max={tier?tier[active].quantity:0} step={1}
						value={preamt}
						trackStyle={{ backgroundColor: '#151516', height: 8, borderRadius: 0 }}
						handleStyle={{
							boxShadow: "0 2px 4px 0 rgba(140, 140, 140, 0.3), 0 -2px 4px 0 rgba(140, 140, 140, 0.2)",
							height: 20,
							width: 20,
							marginLeft: 0,
							marginTop: -6,
							border: 0,
							opacity: 1,
							backgroundColor: '#151516',
						}}
						onChange={(value) => setPreamt(value)}
						railStyle={{ backgroundColor: '#e6e6e6', height: 8, borderRadius: 0 }}
					/>
					<div className={styles.boughtLine}>
						<div className={styles.bought}>{new Intl.NumberFormat('ko-KR').format(preamt)} Token / {new Intl.NumberFormat('ko-KR').format(tier?preamt*tier[active].price:0)} USD</div>
						<img src={usdc_ic} />
					</div>
					<div className={styles.sub2}>Price</div>
				</div>
				<div className={styles.rightSide}>
					<div className={styles.rightBox}>Ownership</div>
					<div className={styles.rightValue}>{new Intl.NumberFormat('ko-KR').format(tier?preamt*tier[active].royalty:0)}%</div>
					<div className={styles.rightSub}>Streaming Royalty</div>
					<div className={styles.empty}></div>
					<div className={styles.rightBox}>Royalty return</div>
					<div className={styles.rightValue}>{new Intl.NumberFormat('ko-KR', {maximumFractionDigits: 1}).format(tier&&data?preamt*tier[active].price*(data["ytm_"+(predict-1)] + 100)/100:0)} USD</div>
					<div className={styles.rightSub}>{data?monthDiff(new Date(data.term_start),new Date(data.term_end)):0} Month Return / USDC</div>
				</div>
			</div>
			
		</div>
	);
}

export default Simulator;