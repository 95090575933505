import React, { useState } from "react";
import { Mobile, PC } from "../../../styles/MediaQuery";

import { userInfo, info } from "../../../services/dummy";

import uparrow from "../../../assets/images/uparrow.png";
import downarrow from "../../../assets/images/downarrow.png";
import cover from "../../../assets/images/cover.png"

import { WhiteTag } from "../../../components/Common/NameTag";
import styles from "./NFTList.module.scss";

const NFTElem = (props) => {
    return (
        <div className={styles.elem}>
            <div className={styles.album} style={{background: `url(${cover})`, backgroundSize: "cover"}}>
                <WhiteTag upText="Royalty NFT" underText="Type: FAN" className={styles.tag} />
            </div>
            <div className={styles.elemTitle}>[아티스트] 음악 이름</div>
            <div className={styles.elemId}># 3612_FAN</div>
            <div className={styles.bottomInfo}>
                <div>
                    <div className={styles.leftTop}>Ownership(Lock-in)</div>
                    <div className={styles.leftBottom}>0.01%(34Month)</div>
                </div>
                <div className={styles.rightBottom} onClick={(e) => {props.setActive(true)}}>Transfer</div>
            </div>
        </div>
    );
}

const NFTList = (props) => {
    const [open, setOpen] = useState([false]);
	return (
		<div className={styles.wrapper}>
			<div className={styles.subtitleDiv}>
				<div className={styles.subtitleEng}>NFT List</div>
			</div>
            <div className={styles.barContainer}>
                {/* <div className={styles.listWrap} onClick={(e) => {setOpen(open.map((c, i) => { if (i === 0) return !c;}))}}>
                    <div className={styles.listTitle}> 
                        <div className={styles.ltLeft}>[헤이즈] 비도오고 그래서 (Feat. 신용재) Royalty NFT</div>
                        <div className={styles.ltRight}>Tokens : 6</div>
                    </div>
                    <div className={styles.arrow}><img src={open[0]?uparrow:downarrow}/></div>
                </div>
                <div className={open[0]?styles.elemWrap:styles.elemHidden} >
                    <NFTElem setActive={props.setActive} />
                    <NFTElem setActive={props.setActive} />
                    <NFTElem setActive={props.setActive} />
                    <NFTElem setActive={props.setActive} />
                    <NFTElem setActive={props.setActive} />
                    <NFTElem setActive={props.setActive} />
                </div> */}
            </div>
		</div>
	);
};

export default NFTList;