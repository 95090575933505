import React, { useState } from "react";
import styles from "./InputBar.module.scss";

import checkIcon from "../../assets/icons/ic-check-bk-24@3x.png";
import refresh from "../../assets/icons/refresh.png";
import { Mobile, PC } from "../../styles/MediaQuery";


const InputBar = (props) => {
	const handleChange = props.onChange
	const errChk = props.errChk?props.errChk:((str) => false)

	return (
		<div className={styles.top}>
			{props.label?(<div className={styles.label}>{props.label}</div>):(<div></div>)}
			<input className={styles.inputs} type={props.type} autoComplete="off" 
				name={props.name} value={props.value} placeholder={props.placeholder} onChange={handleChange} 
				style={errChk(props.value)?{
					borderBottom: "1px #ff5300 solid"
				}:{}}
			/>
			{props.value!=""&&!errChk(props.value)?(<div className={styles.btns}><img src={checkIcon} className={styles.checked} alt="checked" /></div>):(<div></div>)}
			{errChk(props.value)?(<div className={styles.errorLabel}>{errChk(props.value)}</div>):(<div></div>)}
		</div>
	);
}

const InputBarWithBtn = (props) => {
	const handleChange = props.onChange
	const errChk = props.errChk?props.errChk:((str) => false)

	return (
		<div className={styles.top}>
			{props.label?(<div className={styles.label}>{props.label}</div>):(<div></div>)}
			<input className={styles.inputs} type={props.type} autoComplete="off" 
				name={props.name} value={props.value} placeholder={props.placeholder} onChange={handleChange} 
				style={errChk(props.value)?{
					borderBottom: "1px #ff5300 solid"
				}:{}}
			/>
			{props.value!=""&&!errChk(props.value)?(
				<div className={styles.btns}>
					<img src={refresh} className={styles.refresh} onClick={props.onClick} alt="refresh" />
					<img src={checkIcon} className={styles.checked} alt="checked" />
				</div>):(
				<div className={styles.btns}>
					<img src={refresh} className={styles.refresh} onClick={props.onClick} alt="refresh" />
				</div>)}
			{errChk(props.value)?(<div className={styles.errorLabel}>{errChk(props.value)}</div>):(<div></div>)}
		</div>
	);
}

const TripleInputBar = (props) => {
	const handleChange = props.onChange

	return (
		<div className={styles.top}>
			{props.label?(<div className={styles.label}>{props.label}</div>):(<div></div>)}
			<div className={styles.blocks}>
				<input className={styles.blockInput} type={props.type} autoComplete="off" name={props.name + "1"} value={props.value[0]} placeholder={"1" + props.placeholder} onChange={handleChange} />
				<input className={styles.blockInput} type={props.type} autoComplete="off" name={props.name + "2"} value={props.value[1]} placeholder={"2" + props.placeholder} onChange={handleChange} />
				<input className={styles.blockInput} type={props.type} autoComplete="off" name={props.name + "3"} value={props.value[2]} placeholder={"3" + props.placeholder} onChange={handleChange} />
			</div>
		</div>
	);
}

export { InputBar, InputBarWithBtn, TripleInputBar };