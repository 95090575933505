import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import styles from "./TabBanner.module.scss";
import playBtn from "../../../../assets/icons/ic-play-black.svg";
import redLine from "../../../../assets/images/bgline.png";
import revenue from "../../../../assets/images/revenue.png";
import graph from "../../../../assets/images/graph.gif";

import illust4 from "../../../../assets/images/illust4.png";

import Lottie from 'react-lottie-player'
import illust3 from '../../../../assets/lotties/fanft_site_motion_illust_3.json'
import illust2 from '../../../../assets/lotties/fanft_site_motion_illust_graph_edited.json'

import tab1 from "../../../../assets/images/tab1.png";
import tab3 from "../../../../assets/images/tab3.png";

// Toast for test
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const TabBanner = () => {
  const [tabNumber, setTabNumber] = useState(0);
  const [tabImage, setTabImage] = useState([tab1, illust3, tab3]);
  const [isLottie, setIsLottie] = useState([false, true, false]);
  const tabtitle = [
    (<div>Music is a lucrative asset across diverse services.</div>),
    (<div>Invest in early-release royalties for optimal returns.</div>),
    (<div>We invest in royalties with up to 10% stake.</div>)
    ]
  const tabtext = [
    (<div>
      <div className={styles.secContent}>
      Music is an attractive asset generating revenue from various streaming platforms. <br />
      It's a compelling asset with high investor accessibility and liquidity, resilient in downturns.
      </div>
      <div className={styles.proviso}></div>
    </div>),
    (<div>
      <div className={styles.secContent}>
        FANFT's music investment product targets tracks released within the last 3 months and offers royalty returns for a period of 2 years.
      </div>
      <div className={styles.proviso}>
        ※ Music royalties typically peak right after release, staying high for 2 years. <br />
        ※ Older Music have lower earnings but offer stable returns.
      </div>
    </div>),
    (<div>
      <div className={styles.secContent}>
        FANFT invests in various music royalties with up to 10% equity.
        This supports many artists and is safer than investing in an entire song's equity.
      </div>
      <div className={styles.proviso}>
      </div>
    </div>)
    ]

  return (
    <>
    <ToastContainer />
    <div className={styles.container}>
      <div className={styles.miniTitle}>HOW IT WORKS</div>
      <div className={styles.title}>Invest in Music</div>
      <div className={styles.tabGroup}>
        <div className={tabNumber==0?styles.tabBtnActive:styles.tabBtn} onClick={(e) => setTabNumber(0)}>Fund Term</div>
        <div className={tabNumber==1?styles.tabBtnActive:styles.tabBtnMiddle} onClick={(e) => setTabNumber(1)}>Invest Item</div>
        <div className={tabNumber==2?styles.tabBtnActive:styles.tabBtn} onClick={(e) => setTabNumber(2)}>Add-Ons</div>
      </div>
      <div className={styles.outerSec}>
        <div className={styles.section}>
          <div className={styles.rightSide}>
            {isLottie[tabNumber]?(<Lottie loop animationData={tabImage[tabNumber]} play style={{ width: "100%" }} />):(<img src={tabImage[tabNumber]} className={styles.rightSideImg}/>)}
          </div>
          <div className={styles.leftSide}>
            <div className={styles.secTitle}>{tabtitle[tabNumber]}</div>
            {tabtext[tabNumber]}
            <div className={styles.btnList}>
              <div className={styles.detailBtn}>Learn More</div>
              {
                // https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/41736886731468600772501565380683869906844720416563365626931480029747528859649
              /* <div className={styles.infoBtn}>
                <img src={playBtn} /> 
                See Infomation Video
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style={{backgroundColor: "black", width: "100%", height: "1px" }}></div>
    </>
  );
};

export default TabBanner;