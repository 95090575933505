import React, { useState, useEffect } from "react";

import styles from "./Discord.module.scss";
import { info } from "../../../../services/dummy";
import bg from "../../../../assets/images/join_discord.png";

const Discord = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.title} style={{backgroundImage: `linear-gradient(180deg, rgba(25, 25, 25, 0.00) 0%, #000 100%), url(${bg})`}}>Join Our Discord</div>
                <div className={styles.sub}>Purchase your favorite 'Music NFT' to build an investment portfolio, and invest in music streaming revenues.</div>
                <a className={styles.btn} href="https://discord.gg/xRaPcSzT68" target="_blank">Join us</a>
            </div>
        </div>
    );
  };
  
  
  export default Discord;