import React from "react";
import styles from "./Footer.module.scss";
import { Mobile, PC } from "../../../styles/MediaQuery";

import youtube from "../../../assets/images/youtube.png";
import facebook from "../../../assets/images/facebook.png";
import instagram from "../../../assets/images/instagram.png";
import twitter from "../../../assets/images/twitter.png";


const Footer = () => {
	return (
		<div className={styles.invert}>
			<div className={styles.lineWrapper}>
				<div className={styles.topWrapper}>
					<a className={styles.leftTop}>Copyright &copy; 2023 FANFT. All Rights Reserved.</a>
					<div className={styles.rightTop}>
						<div>Follow us on</div>
						<a href="https://youtu.be/xhWzoeEfg_E"><img src={youtube}/></a>
						<img src={facebook}/>
						<img src={instagram}/>
						<img src={twitter}/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;