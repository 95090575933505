import React, { useState } from "react";
import ReactPlayer from 'react-player'

import { Mobile, PC } from "../../../styles/MediaQuery";
import { NavLink } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

import styled from "styled-components";
import styles from "./TopBanner.module.scss";
import video from "../../../assets/videos/movie2.mp4";
import playbtn from "../../../assets/images/playbtn.svg";
import banner1 from "../../../assets/images/banner1.png";
import banner2 from "../../../assets/images/banner2.png";
import banner3 from "../../../assets/images/banner3.png";
import banner4 from "../../../assets/images/banner4.png";
import illust5 from "../../../assets/images/illust5.png";


import Lottie from 'react-lottie-player'
import lottieJson from '../../../assets/lotties/motion_illust_1.json'

const BannerData = [
  {
    type: "img",
    title: (<div>Invest in music<br/>with NFT</div>),
    subtext: (<div>Empowering direct investment in music production <br />and providing opportunities to earn royalties</div>),
    btntext: "Invest",
    content: illust5,
  },
  {
    type: "img",
    title: (<div>Invest in varioust music projects</div>),
    subtext: (<div>Invest in diverse music projects<br />like drama OSTs and K-POP music on FANFT.</div>),
    btntext: "Invest",
    content: banner3,
  },
  {
    type: "img",
    title: (<div>Be with FANFT<br />Join our Discord</div>),
    subtext: (<div>Join the community to receive a variety of <br />investment information and benefits.</div>),
    btntext: "Join us",
    content: banner4,
  }
]

const BannerElement = (props) => {
  const [playing, setPlaying] = useState(true);
  return (
    <div className={styles.elementContainer} style={{
      width: props.isActive?"100%":"0px",
      height: props.isActive?"fit-content":"0px"
    }}>
      <div className={styles.leftSide}>
        <div className={styles.title}>{props.title}</div>
        <div className={styles.subtext}>{props.subtext}</div>
        <div className={styles.btnWrapper}>
          <NavLink className={styles.detailBtn} to="/collection">{props.btntext}</NavLink>
          <div className={styles.investBtn}>Invest Now</div>
        </div>
      </div>
      <div className={styles.rightSide}> 
        { !playing?(<img src={playbtn} onClick={(e) => {setPlaying(!playing)}}  className={styles.playbtn} />):(<></>) }
        <ReactPlayer url={video} height={360} width={585} muted={true} loop={true} playing={playing} onClick={(e) => {playing?setPlaying(!playing):setPlaying(playing)}} />
        {/*<video alt="video" src={video} className={styles.objectFit} controls height={420} width={524} />*/}
      </div>
    </div>
  )
}

const BannerElementImg = (props) => {
  return (
    <div className={styles.elementContainer} style={{
      width: props.isActive?"100%":"0px",
      height: props.isActive?"fit-content":"0px"
    }}>
      <div className={styles.leftSide}>
        <div className={props.isActive?styles.title:styles.titleHidden}>{props.title}</div>
        <div className={props.isActive?styles.subtext:styles.subtextHidden}>{props.subtext}</div>
        <div className={styles.btnWrapper}>
          { props.btntext==""?(<></>):(<NavLink className={props.isActive?styles.detailBtn:styles.detailBtnHidden} to="/collection">{props.btntext}</NavLink>)}
          {/* <div className={styles.investBtn}>Invest Now</div> */}
        </div>
      </div>
      <div className={styles.rightSide}> 
        <img alt="img" src={props.img} className={props.isActive?styles.objectFit:styles.objectFitHidden} height={362} width={585} />
        {/*<video alt="video" src={video} className={styles.objectFit} controls height={420} width={524} />*/}
      </div>
    </div>
  )
}


const BannerElementLottie = (props) => {
  const [playing, setPlaying] = useState(true);
  return (
    <div className={styles.elementContainer} style={{
      width: props.isActive?"100%":"0px",
      height: props.isActive?"fit-content":"0px"
    }}>
      <div className={styles.leftSide}>
        <div className={styles.title}>{props.title}</div>
        <div className={styles.subtext}>{props.subtext}</div>
        <div className={styles.btnWrapper}>
          <NavLink className={styles.detailBtn} to="/collection">{props.btntext}</NavLink>
          {/* <div className={styles.investBtn}>Invest Now</div> */}
        </div>
      </div>
      <div className={styles.rightSide}> 
        <Lottie loop animationData={lottieJson} play style={{ width: 585, height: 360 }} />
        {/* <img alt="video" src={illust1} className={styles.objectFit} controls height={360} width={585} /> */}
      </div>
    </div>
  )
}


const TopBanner = () => {
  const [activeCard, setActiveCard] = useState(0);
  return (
    <Container className={styles.container}>
      <div className={styles.bannerContainer}>
      {
        BannerData.map((item, index) => {
          return (item.type == "img" ? (
            <BannerElementImg
              key={index}
              title={item.title}
              subtext={item.subtext}
              btntext={item.btntext}
              img={item.content}
              isActive={activeCard == index}
            />
          ) : item.type == "lottie" ? (
            <BannerElementLottie
              key={index}
              title={item.title}
              subtext={item.subtext}
              btntext={item.btntext}
              img={item.content}
              isActive={activeCard == index}
            />
          ) : (
            <BannerElement
              key={index}
              title={item.title}
              subtext={item.subtext}
              btntext={item.btntext}
              img={item.content}
              isActive={activeCard == index}
            />
          ))
        })
      }
      </div>
      <div className={styles.paginationContainer}>
      {
        BannerData.map((item, index) => {
          return (
            <div className={activeCard==index?styles.paginationActive:styles.pagination} 
              onClick={(e) => {setActiveCard(index)}}>
              {index+1}
            </div>
          )
        })
      }
      </div>
    </Container>
  );
};

const Container = styled.div`
  .swiper {
    z-index: 0;
  }
  .swiper-pagination {
    bottom: 0px;
  }
  .swiper-pagination-bullet-active {
    background: #ff424d;
  }
`;

export default TopBanner;
