import React, { useState } from "react";
import { Mobile, PC } from "../../../styles/MediaQuery";

import styles from "./About.module.scss";

const About = (props) => {
	const data = props.data;

	function monthDiff(d1, d2) {
		var months;
		months = (d2.getFullYear() - d1.getFullYear()) * 12;
		months -= d1.getMonth();
		months += d2.getMonth();
		return months <= 0 ? 0 : months;
	}

	return (
		<div className={styles.container}>
			<div className={styles.title}>ABOUT</div>
			<div className={styles.row}>
				<div className={styles.left}>Music Info</div>
				<div className={styles.right}>{data?data.title:""}</div>
			</div>
			<div className={styles.row}>
				<div className={styles.left}>Term</div>
				<div className={styles.right}>{`${data?monthDiff(new Date(data.term_start),new Date(data.term_end)):0} Month`}</div>
			</div>
			<div className={styles.row}>
				<div className={styles.left}>Type</div>
				<div className={styles.right}>Music {data&&data.nfttype==0?"Music Royalty NFT":"PFP NFT"}</div>
			</div>
			<div className={styles.row}>
				<div className={styles.left}>Blockchain</div>
				<div className={styles.right}>{data?data.chainid:0}</div>
			</div>
			<div className={styles.row}>
				<div className={styles.left}>Contract Address</div>
				<a className={styles.rightLink} href={`https://mumbai.polygonscan.com/address/0xbe8Ce50ea18444Bc4FD79515e9B7DD3b660f8E54`}>{`${"0xbe8Ce50ea18444Bc4FD79515e9B7DD3b660f8E54".slice(0,6)}...${"0xbe8Ce50ea18444Bc4FD79515e9B7DD3b660f8E54".slice(38,42)} >`}</a>
			</div>
		</div>
	)
};

export default About;