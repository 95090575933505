import { SafeEventEmitterProvider } from "@web3auth/base";
import Web3 from "web3";
import usdc_abi from "./usdc_abi";

const ethProvider = (provider, uiConsole) => {
  const getAccounts = async () => {
    try {
      const web3 = new Web3(provider);
      const accounts = await web3.eth.getAccounts();
      uiConsole("Eth accounts", accounts);
      return accounts;
    } catch (error) {
      console.error("Error", error);
      uiConsole("error", error);
    }
  };

  const getBalance = async () => {
    try {
      const web3 = new Web3(provider);
      const accounts = await web3.eth.getAccounts();
      const balance = await web3.eth.getBalance(accounts[0]);
      uiConsole("Eth balance", balance);
      return balance;
    } catch (error) {
      console.error("Error", error);
      uiConsole("error", error);
    }
  };

  const getUSDCBalance = async () => {
    try {
      const web3 = new Web3(provider);
      const accounts = await web3.eth.getAccounts();
      const usdcContract = new web3.eth.Contract(usdc_abi, "0x0FA8781a83E46826621b3BC094Ea2A0212e71B23");
      const usdcBalance = await usdcContract.methods.balanceOf(accounts[0]).call();
      uiConsole("USDC balance", usdcBalance);
      return usdcBalance;
    } catch (error) {
      console.error("Error", error);
      uiConsole("error", error);
    }                     
  }; 

  const checkAddress = (address) => {
    try {
      const web3 = new Web3(provider);
      const isAddress = web3.utils.isAddress(address);
      uiConsole("isAddress", isAddress);
      return isAddress;
    } catch (error) {
      console.error("Error", error);
      uiConsole("error", error);
    }
  };

  const signMessage = async () => {           
    try {
      const pubKey = (await provider.request({ method: "eth_accounts" }));
      const web3 = new Web3(provider);
      const message = "0x47173285a8d7341e5e972fc677286384f802f8ef42a5ec5f03bbfa254cb01fad";
      (web3.currentProvider).send(
        {
          method: "eth_sign",
          params: [pubKey[0], message],
          from: pubKey[0],
        },
        (err, result) => {
          if (err) {
            return uiConsole(err);
          }
          uiConsole("Eth sign message => true", result);
        }
      );
    } catch (error) {
      console.log("error", error);
      uiConsole("error", error);
    }
  };

  const transfer = async (to, amount, gasprice) => {
    try {
      const web3 = new Web3(provider);
      const accounts = await web3.eth.getAccounts();
      const txRes = await web3.eth.sendTransaction({
        from: accounts[0],
        to: to,
        gas: 21000,
        maxFeePerGas: gasprice,
        maxPriorityFeePerGas: gasprice,
        value: web3.utils.toWei(amount),
      });
      uiConsole("txRes", txRes);
      return txRes;
    } catch (error) {
      console.log("error", error);
      uiConsole("error", error);
      return null;
    }
  };

  const transferUSDC = async (to, amount, gasprice) => {
    try {
      const web3 = new Web3(provider);
      const accounts = await web3.eth.getAccounts();
      const usdcContract = new web3.eth.Contract(usdc_abi, "0x0FA8781a83E46826621b3BC094Ea2A0212e71B23");
      const txRes = await usdcContract.methods.transfer(to, Number(amount)*1e6).send({
        from: accounts[0],
        gas: 75000,
        maxFeePerGas: gasprice,
        maxPriorityFeePerGas: gasprice,
      });
      uiConsole("txRes", txRes);
      return txRes;
    } catch (error) {
      console.log("transferUSDC:error", error);
      uiConsole("transferUSDC:error", error);
      return null;
    }
  };

  const signAndSendTransaction = async () => {
    try {
      const web3 = new Web3(provider);
      const accounts = await web3.eth.getAccounts();
      const txRes = await web3.eth.sendTransaction({
        from: accounts[0],
        to: accounts[0],
        value: web3.utils.toWei("0.01"),
      });
      uiConsole("txRes", txRes);
    } catch (error) {
      console.log("error", error);
      uiConsole("error", error);
    }
  };
  
  const signTransaction = async () => {
    try {
      const web3 = new Web3(provider);
      const accounts = await web3.eth.getAccounts();
      // only supported with social logins (openlogin adapter)
      const txRes = await web3.eth.signTransaction({
        from: accounts[0],
        to: accounts[0],
        value: web3.utils.toWei("0.01"),
      });
      uiConsole("txRes", txRes);
    } catch (error) {
      console.log("error", error);
      uiConsole("error", error);
    }
  };
  return { getAccounts, getBalance, getUSDCBalance, transfer, transferUSDC, checkAddress, signMessage, signAndSendTransaction, signTransaction };
};

export default ethProvider;