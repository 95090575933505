import React, { useState } from "react";
import { Mobile, PC } from "../../../styles/MediaQuery";

import styles from "./InvestNow.module.scss";
import { MiniTimer, MiniTimer2 } from "../../../components/Common/Timer";

import uparrow from "../../../assets/images/uparrow.png";
import downarrow from "../../../assets/images/downarrow.png";
import opensea from "../../../assets/images/OpenSea-Full-Logo.svg";
import { useWeb3Auth } from "../../../services/web3auth";

const TypeElem = (props) => {
	const { provider, login, logout, getUserInfo, getAccounts } = useWeb3Auth();
	const [address, setAddress] = useState("");

	const tier = props.tier
	const data = props.data

	const currentStage = data?new Date() < new Date(data.mint_start)?0:(new Date() < new Date(data.mint_end)?1:2):0

	const getAddress = async () => {
		const addr = await getAccounts();
		if(!addr || addr.length < 1) return;
		if(address != addr[0]) { 
		  setAddress(addr[0]);
		}
	}

	getAddress();

	const noLogin = address == ""
	const noBuy = false
	//props.tier.available == 0
	const name = ["Normal", "FAN", "VIP"]

	function formatNumber (num) {
		return new Intl.NumberFormat('ko-KR', {maximumFractionDigits: 2}).format(num);
	}

	function monthDiff(d1, d2) {
		var months;
		months = (d2.getFullYear() - d1.getFullYear()) * 12;
		months -= d1.getMonth();
		months += d2.getMonth();
		return months <= 0 ? 0 : months;
	}

	return (
		<div className={styles.box}>
    		<div className={styles.titleWrapper} onClick={(e) => props.setActive(props.idx)}>
    			<div className={styles.typeTitle}>{`${name[tier?tier.tier%3:0]} Type`}</div>
    			<div className={styles.flex}>
					{currentStage==0?(<MiniTimer date={data?data.mint_start:"2023-09-10"} />):
					currentStage==1?(<MiniTimer2 date={data?data.mint_end:"2023-09-10"} />):
					(<div className={styles.mintend}>Mint ended</div>)
					}
    				{props.active==props.idx?(<img src={uparrow} className={styles.arrow}/>):(<img src={downarrow} className={styles.arrow}/>)}
    				</div>
    		</div>
    		<div className={props.active==props.idx?styles.active:styles.inactive}>
	    		<div className={styles.infoElemTop}>
	    			<div className={styles.infoTitle}>Price</div>
	    				<div className={styles.infoData}>{`${formatNumber(tier?tier.price:0)} USD`}</div>
	    		</div>
	    		<div className={styles.infoElem}>
	    			<div className={styles.infoTitle}>Ownership</div>
	    			<div className={styles.infoData}>{`${formatNumber(tier?tier.royalty:0)}% Royalty`}</div>
	    		</div>
	    		<div className={styles.infoElem}>
	    			<div className={styles.infoTitle}>Minted</div>
	    			<div className={styles.infoData}>{`${tier?tier.quantity:0}/${tier?tier.quantity:0}`}</div>
	    		</div>
	    		<div className={styles.bar}>
	    			<div className={styles.black} style={{width: `${tier?(tier.quantity)/tier.quantity*100:0}%`}}></div>
	    		</div>
	    		<div className={noLogin?styles.btnLogin:noBuy?styles.btn:currentStage!=1?styles.btn:styles.btn} onClick={(e) => {
					noLogin?login():
					noBuy?props.setBuy(false):
					currentStage!=1?props.setBuy(false):props.setBuy(true)
				}}>{
					noLogin?"Sign in to Collect":
					noBuy?"Sold Out":
					currentStage==0?"Coming Soon":
					currentStage==2?(<img className={styles.opensea} src={opensea}/>):"Buy"
				}</div>
	    	</div>
    	</div>
	)
}

const Investpage = (props) => {
  	return (
    <div className={styles.wrapper}>
    	<div className={styles.container}>
    		<div className={styles.title}>Invest Now</div>
    		{
    			props.tier?props.tier.map((elem, index) => (
    				<TypeElem tier={elem} data={props.data} key={index} setBuy={props.setBuy} idx={index} active={props.active} setActive={props.setActive} />
    			)):(<></>)
    		}
    		
    	</div>
    </div>
  	);
};

export default Investpage;