import React, { useState } from "react";
import { Mobile, PC } from "../../../styles/MediaQuery";

import { useWeb3Auth } from "../../../services/web3auth";
import { userInfo, info } from "../../../services/dummy";
import Chart from "react-apexcharts";

import uparrow from "../../../assets/images/uparrow.png";
import downarrow from "../../../assets/images/downarrow.png";

import mint from "../../../assets/icons/ic_mint.png";
import transfer from "../../../assets/icons/ic_transfer.png";
import claim from "../../../assets/icons/ic_claim.png";
import cover from "../../../assets/images/cover.png";

import styles from "./Transaction.module.scss";

var typeSet = new Set();
var artistSet = new Set();

typeSet.add("All");
artistSet.add("All");

const total = info.length;


const Transaction = () => {
    const [data, setData] = useState(info.slice(0,8));
    const [collectionType, setCollectionType] = useState({All: true, count: 0});
	const [artist, setArtist] = useState({All: true, count: 0});

	for(var i=0; i<data.length; i++) {
		typeSet.add(data[i].type);
		artistSet.add(`${data[i].artist} (${data[i].eng})`);
	}
	const typeList = Array.from(typeSet);
	const artistList = Array.from(artistSet);
    
	return (
		<div className={styles.wrapper}>
			<div className={styles.subtitleDiv}>
				<div className={styles.subtitleEng}>Transaction</div>
				<div className={styles.subtitleKr}>내 거래기록</div>
			</div>
            <div className={styles.collectionTypeWrapper}>
				<div className={styles.filterTitle}>Collecction Type</div>
				<div className={styles.filterList}>
					{typeList.map((elem, idx) => (
						<div key={idx} 
							onClick={(e) => idx==0?
								setCollectionType({All: true, count: 0}):
								setCollectionType(collectionType.hasOwnProperty(elem)?
									{...collectionType, [elem]: !collectionType[elem], count: collectionType[elem]?collectionType.count-1:collectionType.count+1}:
									{...collectionType, [elem]: true, count: collectionType.count+1}
								)
							} className={
								idx==0&&collectionType.count!=0?styles.filterBtn:(collectionType.hasOwnProperty(elem)&&collectionType[elem]?styles.filterBtnActive:styles.filterBtn)
							} 
						>
						{elem}</div>
					))}
				</div>
			</div>
			<div className={styles.collectionTypeWrapperBottom} >
				<div className={styles.filterTitleSec}>Artist (Edition)</div>
				<div className={styles.filterList}>
					{artistList.map((elem, idx) => (
						<div key={idx} 
							onClick={(e) => idx==0?
								setArtist({All: true, count: 0}):
								setArtist(artist.hasOwnProperty(elem)?
									{...artist, [elem]: !artist[elem], count: artist[elem]?artist.count-1:artist.count+1}:
									{...artist, [elem]: true, count: artist.count+1}
								)
							} className={
								idx==0&&artist.count!=0?styles.filterBtn:(artist.hasOwnProperty(elem)&&artist[elem]?styles.filterBtnActive:styles.filterBtn)
							} 
						>
						{elem}</div>
					))}
				</div>
			</div>
            <table className={styles.table}>
                <thead className={styles.header}>
                    <th style={{width: 180, textAlign: "left", paddingLeft: 20}}>Event</th>
                    <th style={{width: 180, textAlign: "left"}}>Item</th>
                    <th style={{width: 140}}>Price</th>
                    <th style={{width: 140}}>Quantity</th>
                    <th style={{width: 140}}>From</th>
                    <th style={{width: 140}}>To</th>
                    <th style={{width: 113, textAlign: "right", paddingRight: 20}}>Time</th>
                </thead>
                <tbody>
                    <tr>
                        <td className={styles.elemTitle}>
                            <div><img src={claim} /> Claim (정산)</div></td>
                        <td className={styles.elemItem}>
                            <div className={styles.elemItemWrapper}>
                                <img src={cover} />
                                <div className={styles.elemItemL}>
                                    <div className={styles.elemItemLT}>‘비가 오는 날에’by FANFT</div>
                                    <div className={styles.elemItemLB}>헤이즈 ‘비가 오는 날에’ Music Royalty_FAN #10115</div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className={styles.priceT}>13.12 USDC</div>
                            <div className={styles.priceB}>16,000 원</div>
                        </td>
                        <td>99</td>
                        <td>0x0d5...1448</td>
                        <td>0x0d5...1448</td>
                        <td className={styles.tx}>2 month ago</td>
                    </tr>
                    <tr>
                        <td className={styles.elemTitle}>
                            <div><img src={transfer} /> Transfer (USDC)</div></td>
                        <td className={styles.elemItem}>
                            <div className={styles.elemItemWrapper}>
                                <div className={styles.elemItemL}>
                                    <div className={styles.elemItemLT}>Transaction Hash</div>
                                    <div className={styles.elemItemLB}>0x8e31ba9403cbfe53338b9f0aafebf2d0d0548489b98ee6d3d7386d11d4f29186</div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className={styles.priceT}>13.12 USDC</div>
                            <div className={styles.priceB}>16,000 원</div>
                        </td>
                        <td>---</td>
                        <td>0x0d5...1448</td>
                        <td>0x0d5...1448</td>
                        <td className={styles.tx}>2 month ago</td>
                    </tr>
                </tbody>
            </table>
            {
				total>data.length?(<div className={styles.moreBtn} >View More</div>):(<div className={styles.blank}></div>)
			}
		</div>
	);
};

export default Transaction;