import React, { useState, useRef } from "react";
import { Mobile, PC } from "../../../styles/MediaQuery";
import { useWeb3Auth } from "../../../services/web3auth";

import styles from "./ClaimModal.module.scss";
import "swiper/css";
import "swiper/css/navigation";

import x from "../../../assets/icons/group-9-copy-2.svg";
import left from "../../../assets/icons/ic_left.png";
import right from "../../../assets/icons/ic_right.png";
import usdc_ic from "../../../assets/images/usdc_small.png";
import usdc from "../../../assets/images/usdc.png";

import profile from "../../../assets/images/profile.png";
import cover from "../../../assets/images/cover.png";

const balance = 2208.1

const ClaimModal = (props) => {
	const setBuy = props.setActive;
	const type = props.type;
	const data = props.data;
	const [amt, setAmt] = useState(1);
    const [page, setPage] = useState(0);

    const { provider, login, logout, getUserInfo, getAccounts } = useWeb3Auth();
    const [address, setAddress] = useState("");
  
    const getAddress = async () => {
      const addr = await getAccounts();
      if(!addr || addr.length < 1) return;
      if(address != addr[0]) {
        setAddress(addr[0]);
      }
    }

    const shorten = (address) => {
        return `${address.slice(0,5)}...${address.slice(38,42)}`
    }

	const handleAmt = (e) => {
		setAmt(e.target.value);
	}
    getAddress();
	return (
		<div className={styles.modalBg}>
			<div className={styles.modalPayment}>
                {   page === 1 ? (<div className={styles.prev} onClick={(e) => setPage(0)}>
					<img src={right} />
				</div>):(<></>)}
				<div className={styles.modalTitlePayment}>
					Claim
				</div>
				<div className={styles.close} onClick={(e) => setBuy(false)}>
					<img src={x} />
				</div>
                {   page === 0 ? (
                    <>
                        <div className={styles.modalTypeBox}>Reward Round: 3/36</div>
                        <div className={styles.modalPriceBox}><img src={usdc} />{`${new Intl.NumberFormat('ko-KR').format(31.36)} USD`}</div>
                        <div className={styles.modalPerBox}>Streaming Royalty / USDC</div>
                        <div className={styles.modalLine}>
                            <div className={styles.modalLineTitle}>Tokens</div>
                            <div className={styles.amtBox}>
                                <div className={styles.amt} >{amt}</div>
                            </div>
                        </div>
                        <div className={styles.modalLine}>
                            <div className={styles.modalLineTitle}>Ownership(%)</div>
                            <div className={styles.modalLineValue}>{`${new Intl.NumberFormat('ko-KR').format(0.013 * amt)}`}%</div>
                        </div>
                        <div className={styles.modalLineLast}>
                            <div className={styles.modalLineTitle}>Total Price</div>
                            <div className={styles.modalLineValueBlue}>{new Intl.NumberFormat('ko-KR').format(31.36 * amt)} USDC</div>
                        </div>
                        <div className={styles.balanceLine}>
                            <div className={styles.modalLineTitle}>Balance</div>
                            <div className={styles.balanceBox}>
                                <div className={styles.balanceLeft}>
                                    <img src={usdc_ic} className={styles.balanceImg} />
                                    <div className={styles.balanceTicker}>USDC</div>
                                </div>
                                <div className={styles.balanceAmt}>{new Intl.NumberFormat('ko-KR').format(balance)} USDC</div>
                            </div>
                        </div>
                        <div className={styles.buyBtn} onClick={(e) => setPage(1)}>Claim</div> 
                    </>) : (
                    <>
                        <div className={styles.minting}>Claim</div>
                        <div className={styles.tolist}>
                            <div className={styles.address}>
                                <img className={styles.profilePic} src={cover}/> {shorten("0x1234567890123456789012345678901234567890")}
                            </div>
                            <img src={left} className={styles.left} />
                            <div className={styles.address}>
                                <img className={styles.profilePic} src={profile}/> {shorten(address)}
                            </div>
                        </div>
                        <div className={styles.col}>
                            <div className={styles.tableTitle}>Claim Amount</div>
                            <div className={styles.tableElem}><img src={usdc_ic} className={styles.balanceImg} />{new Intl.NumberFormat('ko-KR').format(32.63 * amt)} USDC</div>
                        </div>
                        <div className={styles.col}>
                            <div className={styles.tableTitle}>Estimated gas fee <br></br>(예상 가스요금)</div>
                            <div className={styles.tableElem}>0.00004 ETH</div>
                        </div>
                        <div className={styles.maxgas}>최대 요금 0.00004312 EHT</div>
                        <div></div>
                        <div className={styles.noBal2}><span className={styles.insufficent}>자산이 부족합니다.</span> / <a className={styles.charge}>충전하기</a></div>
                        <div className={styles.btnList}>
                            <div className={styles.reject} onClick={(e) => {setBuy(false)}}>Reject (취소)</div>
                            <div className={styles.confirm} onClick={(e) => {setBuy(false)}}>Confirm (확인)</div>
                        </div>
                    </>
                    )
                }
			</div>
		</div>
	);
}

export default ClaimModal;