import React, { useState, useEffect } from "react";
import { Mobile, PC } from "../../styles/MediaQuery";
import { useLocation } from "react-router-dom";

import TopBanner from "./TopBanner/TopBanner";
import InvestNow from "./InvestNow/InvestNow";
import ProjectInfo from "./ProjectInfo/ProjectInfo";
import RewardDetail from "./RewardDetail/RewardDetail";
import Simulator from "./Simulator/Simulator";
import About from "./About/About";
import Collectors from "./Collectors/Collectors";
import ProjectDetail from "./ProjectDetail/ProjectDetail";
import InvestDetail from "./InvestDetail/InvestDetail";
import MusicInfo from "./MusicInfo/MusicInfo";

import FlowBanner from "../main/FlowBanner/FlowBanner";
import Footer from "../main/Footer/Footer";
import Wallet from "../../components/Wallet/Wallet";

import styles from "./Investpage.module.scss";
import topBtn from "../../assets/icons/chevron-top.svg";

import { info } from "../../services/dummy";
import { createPool } from "@vercel/postgres";
import MintModal from "./MintModal/MintModal";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const menu = ["Invest", "Project", "Music", "Detail"]

const TopBtn = (props) => {
  return (
    <div className={styles.topBtn} onClick={props.onClick} >
      <img src={topBtn} />
    </div>
  );
}

const Investpage = () => {
  const query = useQuery();
  const [current, setCurrent] = useState(menu[0]);
  const [active, setActive] = useState(0);
  const [buy, setBuy] = useState(false);
  const [data, setData] = useState(null);
  const [tier, setTier] = useState(null);
  const [timeline, setTimeline] = useState(null);
  const [docs, setDocs] = useState(null);

  function goTop() {
    window.scrollTo(0, 0);
  }

  const getInfoPid = async () => {
		const pool = createPool({
			connectionString: process.env.REACT_APP_POSTGRES_URL,
		})
		const { rows } = await pool.sql`SELECT * from "FANFT_Products" WHERE pid = ${query.get("pid")}`; 
		return rows;
	}

  const getTierPid = async () => {
		const pool = createPool({
			connectionString: process.env.REACT_APP_POSTGRES_URL,
		})
		const { rows } = await pool.sql`SELECT * from "FANFT_Tier" WHERE pid = ${query.get("pid")}`; 
		return rows;
	}

  const getTimelinePid = async () => {
    const pool = createPool({
      connectionString: process.env.REACT_APP_POSTGRES_URL,
    })
    const { rows } = await pool.sql`SELECT * from "FANFT_Timeline" WHERE pid = ${query.get("pid")}`;
    return rows;
  }

  const getDocsPid = async () => {
    const pool = createPool({
      connectionString: process.env.REACT_APP_POSTGRES_URL,
    })
    const { rows } = await pool.sql`SELECT * from "FANFT_Docs" WHERE pid = ${query.get("pid")}`;
    return rows;
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    getInfoPid().then((res) => {
			if (!data) setData(res[0]);
		});
    getTierPid().then((res) => {
      if (!tier) setTier(res);
    });
    getTimelinePid().then((res) => {
      if (!timeline) setTimeline(res);
    });
    getDocsPid().then((res) => {
      if (!docs) setDocs(res);
    });
  }, [])

  console.log(data)

  return (
    <>
      { 
				buy?(
					<MintModal setBuy={setBuy} cover={info[query.get("pid")-1].cover} contract={info[query.get("pid")-1].contract} type={active} data={info[query.get("pid")-1].tier[active]}/>
				):(<></>)
			}
      <TopBtn onClick={goTop}/>
      <TopBanner pid={query.get("pid")} data={data} tier={tier} />
      <div className={styles.tabMenu}>
        <div onClick={(e) => setCurrent(menu[0])} className={current == menu[0] ? styles.menuElemActive : styles.menuElem}>Invest Info</div>
        <div onClick={(e) => setCurrent(menu[1])} className={current == menu[1] ? styles.menuElemActive : styles.menuElem}>Project Details</div>
        <div onClick={(e) => setCurrent(menu[2])} className={current == menu[2] ? styles.menuElemActive : styles.menuElem}>About Music</div>
        <div onClick={(e) => setCurrent(menu[3])} className={current == menu[3] ? styles.menuElemActive : styles.menuElem}>Documents</div>
      </div>
      <div className={styles.line}></div>
      <div className={styles.contents}>
        { current == menu[0] ? (<div className={styles.leftSide}>
          <ProjectInfo data={data}/>
          <RewardDetail buy={buy} setBuy={setBuy} active={active} setActive={setActive} data={data} tier={tier}/>
          <Simulator active={active} setActive={setActive} data={data} tier={tier} />
          <About data={data} />
          {/* <Collectors data={info[query.get("pid")-1]} /> */}
        </div>) : (
          current == menu[1] ? (<ProjectDetail data={data} timeline={timeline}/>) : (
            current == menu[2] ? (<MusicInfo data={data} />) : (<InvestDetail docs={docs} />)
          )
        )}
        <InvestNow active={active} setBuy={setBuy} setActive={setActive} tier={tier} data={data} />
      </div>
      <FlowBanner />
      <Footer />
    </>
  );
};

export default Investpage;