import React, { useState } from "react";

import { Mobile, PC } from "../../../styles/MediaQuery";
import { NavLink } from "react-router-dom";

import playBtn from "../../../assets/icons/group-10-copy-5.svg";
import arrow from "../../../assets/icons/chevron-left-24.svg"
import kleva from "../../../assets/images/kleva.png";
import styles from "./Guide.module.scss";

const typeName = ["All", "이용 가이드", "지갑 이용 및 가상자산 가이드", "USDC 구매 및 충전 가이드", "NFT 구매 및 정산, 전송 가이드", "투자 상품 가이드"]
const guideList = [
	{ name: "서비스 이용 가이드", detail: "소셜 로그인을 통한 회원가입 방법과 FANFT서비스를 이용하는 방법이 담긴 영상", content: "VIDEO", img: kleva, type: "이용 가이드" },
	{ name: "서비스 이용 가이드", detail: "소셜 로그인을 통한 회원가입 방법과 FANFT서비스를 이용하는 방법이 담긴 영상", content: "VIDEO", img: kleva, type: "지갑 이용 및 가상자산 가이드" },
	{ name: "서비스 이용 가이드", detail: "소셜 로그인을 통한 회원가입 방법과 FANFT서비스를 이용하는 방법이 담긴 영상", content: "DOCS", img: kleva, type: "USDC 구매 및 충전 가이드" },
	{ name: "서비스 이용 가이드", detail: "소셜 로그인을 통한 회원가입 방법과 FANFT서비스를 이용하는 방법이 담긴 영상", content: "DOCS", img: kleva, type: "NFT 구매 및 정산, 전송 가이드" },
	{ name: "서비스 이용 가이드", detail: "소셜 로그인을 통한 회원가입 방법과 FANFT서비스를 이용하는 방법이 담긴 영상", content: "DOCS", img: kleva, type: "투자 상품 가이드" }
] 

const GuideElement = (props) => {
	return (
		<div className={styles.elemWrapper}>
			<div className={styles.videoBox} style={{backgroundImage: `url(${props.img})`}}>
				<div className={props.content=="VIDEO"?styles.redBox:styles.blueBox}>{props.content}</div>
				{props.content=="VIDEO"?(<img className={styles.playbtn} src={playBtn} />):(<></>)}
			</div>
			<div className={styles.elemTitle}>{props.title}</div>
			<div className={styles.elemDetail}>{props.detail}</div>
		</div>
	);
};

const Guide = () => {
	const [current, setCurrent] = useState(0);
	const result = guideList.filter(e => (e.type == typeName[current] || current==0))
	return (
		<div className={styles.wrapper}>
			<div className={styles.titleWrapper}>
				<div className={styles.title}>서비스 이용 가이드</div>
				<div className={styles.docsBtn}>Read the docs <img src={arrow}/></div>
			</div>
			<div className={styles.typesWrapper}>
				<div className={styles.types}>Types</div>
				<div className={styles.btnList}>
					{typeName.map((elem, idx) => (
						<div className={idx==current?styles.active:styles.inactive} onClick={()=>setCurrent(idx)}>{elem}</div>
					))}
				</div>
			</div>
			<div className={styles.elemList}>
				{result.map((elem) => (
					<GuideElement 
						title={elem.name}
						detail={elem.detail}
						content={elem.content}
						img={elem.img}
					/>
				))}
				{
					new Array(3-(result.length % 3)).fill(0).map((elem) => (
						<div className={styles.dummy}></div>
					))
				}
			</div>
		</div>
	);
};

export default Guide;
