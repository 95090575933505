import React, { useState, useRef } from "react";
import { Mobile, PC } from "../../../styles/MediaQuery";

import styles from "./RewardDetail.module.scss";
import { benefits } from "../../../services/dummy";
import { WhiteTag } from "../../../components/Common/NameTag";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";

import x from "../../../assets/icons/group-9-copy-2.svg";
import rbtn from "../../../assets/icons/chevron->.svg";

import normal from "../../../assets/lotties/grade_icon_normal.mp4";
import fan from "../../../assets/lotties/grade_icon_fan.mp4";
import vip from "../../../assets/lotties/grade_icon_vip.mp4";

const rewardTier = [
	{name: "Normal",image: normal},
	{name: "FAN",image: fan},
	{name: "VIP",image: vip},
];


const RewardModal = (props) => {
	const modal = props.modal;
	const setModal = props.setModal;
	return (
		<div className={styles.modalBg}>
			<div className={styles.modal}>
				<div className={styles.modalTitle}>
					Avaliable
				</div>
				<div className={styles.close} onClick={(e) => setModal(-1)}>
					<img src={x} />
				</div>
				<img className={styles.eventImg} src={benefits[modal].img} />
				<div className={styles.eventName}>{benefits[modal].name}</div>
				<div className={styles.eventAmt}>{benefits[modal].amt}</div>
				<div className={styles.eventDetailTitle}>Details</div>
				<div className={styles.eventDetail}>{benefits[modal].detail}</div>
				<div onClick={(e) => setModal(-1)} className={styles.doneBtn}>Done</div>
			</div>
		</div>
	);
}


const RewardDetail = (props) => {
	const data = props.data;
	const tier = props.tier;
	console.log(data, tier)
	const [modal, setModal] = useState(-1);

	function formatNumber (num) {
		return new Intl.NumberFormat('ko-KR', {maximumFractionDigits: 2}).format(num);
	}

	function monthDiff(d1, d2) {
		var months;
		months = (d2.getFullYear() - d1.getFullYear()) * 12;
		months -= d1.getMonth();
		months += d2.getMonth();
		return months <= 0 ? 0 : months;
	}

	const prevRef = useRef(null);
  	const nextRef = useRef(null);
	return (
		<div className={styles.container}>
			{
				modal>=0?(
					<RewardModal setModal={setModal} modal={modal} />
				):(<></>)
			}
			<div className={styles.titleContainer}>
				<div className={styles.title}>Type of investment product</div>
				{/* <div className={styles.subtitle}>투자상품 종류</div> */}
			</div>
			<div className={styles.typeList}>
				<div className={styles.typeTitle}>Types</div>
				{
					tier?tier.map((tierElem, idx) => 
						(<div className={idx==props.active?styles.typeActive:styles.typeElem} key={idx} onClick={(e) => props.setActive(idx)}>{rewardTier[tierElem.tier%3].name}</div>)
					):(<></>)
				}
			</div>
			<div className={styles.detailWrapper}>
				<div className={styles.leftSide}>
					<div className={styles.cover} style={{backgroundImage: `url(${data?data.cover:""})`}}>
						<video src={rewardTier[props.active].image} autoPlay muted loop />
						<WhiteTag className={styles.tag} upText={rewardTier[props.active].name} underText={`${tier?tier[props.active].quantity:0} / ${tier?tier[props.active].quantity:0} Available NFTs`} />
					</div>
					<div>
						<div className={styles.artists}>{data?data.artist_eng:""}</div>
						{
							(`${data?data.title:""} ${data&&data.nfttype==0?"Music Royalty NFT":"PFP NFT"}`).length > 13 ?
							(<div className={styles.detailTitle}>
								<div>{`${data?data.title:""} ${data&&data.nfttype==0?"Music Royalty NFT":"PFP NFT"}`}&nbsp;&nbsp;</div>
								<div>{`${data?data.title:""} ${data&&data.nfttype==0?"Music Royalty NFT":"PFP NFT"}`}&nbsp;&nbsp;</div>
							</div>):
							(<div className={styles.detailTitleNoLoop}>{`${data?data.title:""} ${data&&data.nfttype==0?"Music Royalty NFT":"PFP NFT"}`}</div>)
						}
					</div>
					<div className={styles.bottomWrapper}>
						<div>
							<div className={styles.bottomTitle}>Min Price</div>
							<div className={styles.bottomPrice}>{`${new Intl.NumberFormat('ko-KR').format(tier?tier[props.active].price:0)} ${"USD"}`}</div>
						</div>
						<div className={styles.bottomRight}>
							<div className={styles.bottomTitle}>Yield of Maturity</div>
							<div className={styles.bottomYtd}>{`${data?formatNumber(data.ytm_1):0}% (${data?monthDiff(new Date(data.term_start),new Date(data.term_end)):0}M)`}</div>
						</div>
					</div>
				</div>
				<div className={styles.rightSide}>
					<div className={styles.mintTitle}>Mint Details</div>
					<div className={styles.textWrapper}>
						<div className={styles.textTitle}>Collection Type</div>
						<div className={styles.textElem}>Music {data&&data.nfttype==0?"Music Royalty NFT":"PFP NFT"}</div>
					</div>
					<div className={styles.textWrapper}>
						<div className={styles.textTitle}>Edition</div>
						<div className={styles.textElem}>{`${tier?tier[props.active].quantity:0} Available / ${tier?tier[props.active].quantity:0} Tokens`}</div>
					</div>
					<div className={styles.textWrapper}>
						<div className={styles.textTitle}>Price(royalty)</div>
						<div className={styles.textElem}>{new Intl.NumberFormat('ko-KR').format(tier?tier[props.active].price:0)} {"USD"} ({formatNumber(tier?tier[props.active].royalty:0)}% royalty)</div>
					</div>
					<div className={styles.inline}>
						<div className={styles.holderTitle}>
							Holder Benefits ({tier?tier[props.active].benefit.toFixed(0).length:0})
							{/* <span className={styles.holderSub}>구매(보유)자 혜택</span> */}
						</div>
						<div className={styles.buttons}>
							<div className={styles.rbtn} ref={prevRef}>
								<img src={rbtn} />
							</div>
							<div className={styles.lbtn} ref={nextRef}>
								<img src={rbtn} />
							</div>
						</div>
					</div>
					<div className={styles.benefitContainer}>
					<Swiper
						style={{padding: '2px'}}
						// onSlideChange={(swiper) => setCurrent(swiper.realIndex)}
						slidesPerView={3}
						slidesPerGroup={1}
						loop={false}
						onInit={(swiper) => {
							swiper.params.navigation.prevEl = prevRef.current;
							swiper.params.navigation.nextEl = nextRef.current;
							swiper.navigation.init();
							swiper.navigation.update();
						  }}
						navigation={true}
						slideToClickedSlide={false}
						spaceBetween={24}
						modules={[Navigation]}
						className="dropSwiper"
	  				>
						{
							tier?tier[props.active].benefit.toFixed(0).split('').map((i) => (
								<SwiperSlide key={i} className={styles.benefitWrapper}>
									<div className={styles.benefitElem} onClick={(e) => setModal(i)}>
										<img src={benefits[i].img} className={styles.benefitImg} />
										<div className={styles.benefitTitle}>{benefits[i].name}</div>
										<div className={styles.benefitSub}>{benefits[i].subtext}</div>
									</div>
								</SwiperSlide>
							)):(<></>)
						}
					</Swiper>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RewardDetail;