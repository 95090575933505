import React, { useState, useRef } from "react";
import { Mobile, PC } from "../../../styles/MediaQuery";

import styles from "./InvestDetail.module.scss";

const InvestDetail = (props) => {
    const docs = props.docs;
	return (
        <>
            <div style={{marginBottom: 70}}>
                <div className={styles.contents}>
                    <div className={styles.title}>Documents</div>
                    <div className={styles.subtitle}>Legal Docs</div>
                    <div>
                        {
                            docs?docs.map((doc, idx) => (
                                <div className={styles.detailElem} key={idx}>
                                    <div>{doc.name}</div>
                                    <a className={styles.blue} href={doc.link} target="_blank">Read Now &gt;</a>
                                </div>
                            )):(<></>)
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default InvestDetail;