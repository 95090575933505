import React, { useState, useEffect } from "react";
import { Mobile, PC } from "../../styles/MediaQuery";
import { isMobile } from 'react-device-detect';

import TopBanner from "./TopBanner/TopBanner";
import Featured from "./Featured/Featured";
import TabBanner from "./TabBanner/TabBanner";
import NFTDrops from "./NFTDrops/NFTDrops";
import TopCollection from "./TopCollection/TopCollection";
import ShoutOut from "./ShoutOut/ShoutOut";
import Guide from "./Guide/Guide";
import Faq from "./Faq/Faq";
import FlowBanner from "./FlowBanner/FlowBanner";
import Footer from "./Footer/Footer";
import Indicators from "../main/Indicators/Indicators";

const Mainpage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
    if(document.documentElement.clientWidth < 1080) {
      console.log("mobile")
      window.location.href = "/mobile"
    }
  }, [])
  
  return (
    <div style={{minWidth: 1080, overflow: "hidden"}}>
      <TopBanner />
      <Indicators />
      <Featured />
      <TabBanner />
      <NFTDrops />
      <TopCollection /> 
      <ShoutOut /> 
      {/* <Guide /> */}
      <Faq />
      <FlowBanner />
      <Footer />
    </div>
  );
};

export default Mainpage;
