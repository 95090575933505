import React, { useState, useEffect, useCallback } from "react";
import styles from "./MusicPlayer.module.scss";

import { NavLink } from "react-router-dom";

import over from "../../assets/images/over2.jpeg";
import left from "../../assets/icons/left.svg";
import right from "../../assets/icons/right.svg";
import play from "../../assets/icons/play.svg";
import pause from "../../assets/icons/pause.svg";
import headset from "../../assets/icons/headset.svg";
import muteIcon from "../../assets/icons/mute.svg";

import overSong from "../../assets/videos/OverSound.m4a";

function seconds2time (seconds) {
    var hours   = Math.floor(seconds / 3600);
    var minutes = Math.floor((seconds - (hours * 3600)) / 60);
    var seconds = seconds - (hours * 3600) - (minutes * 60);
    var time = "";

    if (hours != 0) {
      time = hours+":";
    }
    if (minutes != 0 || time !== "") {
      minutes = (minutes < 10 && time !== "") ? "0"+minutes : String(minutes);
      time += minutes+":";
    }
    if (time === "") {
      time = "0:";
      time += (seconds < 10) ? "0"+seconds : String(seconds);
    }
    else {
      time += (seconds < 10) ? "0"+seconds : String(seconds);
    }
    return time;
}

const MusicPlayer = (props) => {
    const [playing, setPlaying] = useState(false);
    const [volume, setVolume] = useState(0.5);
    const [currentTime, setCurrentTime] = useState(0);
    const [scope, setScope] = useState(0);
    const [mute, setMute] = useState(false);
    const [duration, setDuration] = useState(0);

    const [audio] = useState(new Audio(overSong));

    useEffect(() => {
        playing ? audio.play() : audio.pause();
      },
      [playing]
    );

    audio.addEventListener('loadedmetadata', (e) => {
        setDuration(e.target.duration);
    });

    audio.addEventListener("timeupdate", function () {
        let time = this.currentTime;
        setCurrentTime(time);
    });

    audio.volume = mute?0:volume;
  

    const handleVolume = (event) => {
        setVolume((event.clientX - event.target.offsetLeft) / event.target.offsetWidth);
    };
    const handleVolumeScope = (event) => {
        if (scope != 1) return;
        setVolume((event.clientX - event.target.offsetLeft) / event.target.offsetWidth);
    };

    const handleTime = (event) => {
        audio.currentTime = (event.clientX - event.target.getBoundingClientRect().left) / event.target.offsetWidth * duration;
    };
    const handleTimeScope = (event) => {
        if (scope != 2) return;
        audio.currentTime = (event.clientX - event.target.getBoundingClientRect().left) / event.target.offsetWidth * duration;
    };

    return (<div className={styles.container}>
        <div className={styles.wrapper}>
            <div className={styles.upSide}>
                <div className={styles.upSideLeft}>
                    <img src={over} className={styles.cover}/>
                    <div className={styles.controllers}>
                        <div className={styles.left}><img src={left} /></div>
                        <div className={styles.playBtn} onClick={() => setPlaying(!playing)}><img src={playing?pause:play} /></div>
                        <div className={styles.right}><img src={right}/></div>
                    </div>
                    <div className={styles.songInfo}>
                        <div className={styles.artist}>Kim Yejoon</div>
                        <div className={styles.title}>Over</div>
                    </div>
                </div>
                <div className={styles.upSideRight}>
                    <div className={styles.volume}>
                        <img src={volume==0||mute?muteIcon:headset} onClick={() => setMute(!mute)} />
                        <div className={styles.clickVolume} onClick={handleVolume} onMouseDown={() => setScope(1)} onMouseUp={() => setScope(0)} onMouseMove={handleVolumeScope}>
                            <div className={styles.displayVolume} style={{background: mute?"#e6e6e6":`linear-gradient(to right, black ${100*volume}%, #e6e6e6 ${100*volume}%)`}}></div>
                        </div>
                    </div>
                    <NavLink to="/invest?pid=1" className={styles.investBtn}>Invest Now</NavLink>
                </div>
            </div>
            <div className={styles.downSide}>
                <div className={styles.currentTime}>{seconds2time(Math.floor(currentTime))}</div>
                <div className={styles.clickTime} >
                    <div className={styles.displayTime} onClick={(e) => {e.stopPropagation()}} style={{background: "#e6e6e6"}} >
                        <div className={styles.black}  style={{background: "black", width: `${100*currentTime/duration}%`}}></div>
                    </div>
                    <div className={styles.clickLayer} onClick={handleTime} onMouseDown={() => setScope(2)} onMouseUp={() => setScope(0)} onMouseMove={handleTimeScope}></div>
                </div>
                <div className={styles.endTime}>{seconds2time(Math.floor(duration))}</div>
            </div>
        </div>
    </div>)
};

export default MusicPlayer;