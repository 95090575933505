import React, { useState, useEffect, useCallback } from "react";
import styles from "./NFTWallet.module.scss";
import { NavLink } from "react-router-dom";

import { useWeb3Auth } from "../../services/web3auth";

import x from "../../assets/icons/group-9-copy-2.svg";
import copy from "../../assets/icons/black_copy.svg";
import usdc_ic from "../../assets/images/usdc_small.png";
import eth from "../../assets/images/eth.png";
import usdc from "../../assets/images/usdc.png";
import uparrow from "../../assets/images/uparrow.png";
import downarrow from "../../assets/images/downarrow.png";

import left from "../../assets/icons/ic_left.png";
import right from "../../assets/icons/ic_right.png";
import profile from "../../assets/images/profile.png";
import cover from "../../assets/images/cover.png";


const NFTWallet = (props) => {
  const { provider, login, logout, getUserInfo, getAccounts } = useWeb3Auth();
  const [active, setActive] = useState(false);
  const [address, setAddress] = useState("");
  const [crypto, setCrypto] = useState(0);
  const [page, setPage] = useState(0);
  const [targetAddr, setTargetAddr] = useState("");

  const getAddress = async () => {
    const addr = await getAccounts();
    if(!addr || addr.length < 1) return;
    if(address != addr[0]) {
      setAddress(addr[0]);
    }
  }

  const shorten = (address) => {
    return `${address.slice(0,5)}...${address.slice(38,42)}`
  }

  getAddress();


  const onChange = (e) => {
    console.log(e.target.value.length)
    if(e.target.value.length > 32) {
      setTargetAddr(targetAddr)
    }
    else {
      setTargetAddr(e.target.value.replace(/[^0-9a-fA-F]/g, ''))
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {   page !== 0 ? (<div className={styles.prev} onClick={(e) => setPage(page-1)}>
					<img src={right} />
				</div>):(<></>)}
        <img className={styles.x} onClick={(e) => props.setActive(false)} src={x} />
        <div className={styles.title}>{page==0?"My Wallet":"Transfer"}</div>
        {page==0?(<>
          <div className={styles.addr}>
            To (받는 지갑 주소)
          </div>
          <textarea className={styles.input} onInput={onChange} placeholder="보낼 사람의 주소를 입력해주세요" maxLength="32" contenteditable="true" value={targetAddr}></textarea>
          <div className={styles.line}></div>
          <div className={styles.assetContainer}>
            <div className={styles.assetTitle}>Asset</div>
            <div className={styles.assetWrapper}>
              <img src={cover} className={styles.assetImg} />
              <div>
                <div className={styles.assetType}>ERC-721 Token</div>
                <div className={styles.assetDetail}>‘노래이름’ Invest Project</div>
                <div className={styles.assetDetail}>by FANFT</div>
                <div className={styles.assetDetail}># 3612_FAN</div>
              </div>
            </div>
          </div>
          <div className={styles.btnList}>
            <div className={styles.reject} onClick={(e) => {props.setActive(false)}}>Reject (취소)</div>
            <div className={styles.confirm} onClick={(e) => {setPage(2)}}>Next (다음)</div>
          </div>
        </>
        ):(<>
          <div className={styles.minting}>Transfer</div>
          <div className={styles.tolist}>
            <div className={styles.address}>
              <img className={styles.profilePic} src={cover}/> {shorten("0x1234567890123456789012345678901234567890")}
            </div>
            <img src={left} className={styles.left} />
            <div className={styles.address}>
              <img className={styles.profilePic} src={profile}/> {shorten(address)}
            </div>
          </div>
          <div>
            <div className={styles.assetTitle}>Asset</div>
            <div className={styles.assetWrapper}>
              <img src={cover} className={styles.assetImg} />
              <div>
                <div className={styles.assetType}>ERC-721 Token</div>
                <div className={styles.assetDetail}>‘노래이름’ Invest Project</div>
                <div className={styles.assetDetail}>by FANFT</div>
                <div className={styles.assetDetail}># 3612_FAN</div>
              </div>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.tableTitle}>Estimated gas fee <br></br>(예상 가스요금)</div>
            <div className={styles.tableElem}>0.00004 ETH</div>
          </div>
          <div className={styles.maxgas}>최대 요금 0.00004312 EHT</div>
          <div></div>
          <div className={styles.noBal}><span className={styles.insufficent}>자산이 부족합니다.</span> / <a className={styles.charge}>충전하기</a></div>
          <div className={styles.btnList2}>
            <div className={styles.reject} onClick={(e) => {props.setActive(false)}}>Reject (취소)</div>
            <div className={styles.confirm} onClick={(e) => {props.setActive(false)}}>Confirm (확인)</div>
          </div>
        </>)
      }
      </div>
    </div>
  );
};

export default NFTWallet;
