import React, { useState } from "react";

import { Mobile, PC } from "../../../styles/MediaQuery";
import { NavLink } from "react-router-dom";

import styles from "./TopBanner.module.scss";
import { Timer } from "../../../components/Common/Timer";
import { info } from "../../../services/dummy"

import dots from "../../../assets/images/dots.png";
import share from "../../../assets/images/share.png";
import twitter from "../../../assets/icons/ic_twitter.png";
import icurl from "../../../assets/icons/ic_url.png";
import guide from "../../../assets/icons/ic_guide.png";
import exchange from "../../../assets/icons/ic_exchange2.png";

import {CopyToClipboard} from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TopBanner = (props) => {
	const data = props.data
	const tier = props.tier
	const [clicked, setClicked] = useState(false)
	const [clicked2, setClicked2] = useState(false)

	const currentStage = data?new Date() < new Date(data.mint_start)?0:(new Date() < new Date(data.mint_end)?1:2):0
	const stage = ["Timer","Minting now", "Mint ended"]

	function formatNumber (num) {
		return new Intl.NumberFormat('ko-KR', {maximumFractionDigits: 2}).format(num);
	}

	function monthDiff(d1, d2) {
		var months;
		months = (d2.getFullYear() - d1.getFullYear()) * 12;
		months -= d1.getMonth();
		months += d2.getMonth();
		return months <= 0 ? 0 : months;
	}

	return (
		<div className={styles.wrapper}>
			<ToastContainer
				position="bottom-right"
			/>
			<div className={styles.container}>
				<div className={styles.album} style={{ backgroundImage: `url(${data?data.cover:""})`}}>
				<div className={styles.timer}>
					{currentStage==0?(<Timer date={data?data.mint_start:"2023-09-10"} />):(<div className={currentStage==1?styles.stage:styles.stage2} style={{color: currentStage==1?"#34c77b":"#fff"}}>
						<div className={currentStage==1?styles.circle:styles.circle2} style={{backgroundColor: currentStage==1?"#34c77b":"#fff"}}></div>{stage[currentStage]}</div>)}
				</div>
				</div>
				<div className={styles.rightSide}>
					<div className={styles.period}>Term : {data?monthDiff(new Date(data.term_start),new Date(data.term_end)):0} Month</div>
					{
						(`[${data?data.artist_eng:""}] ${data?data.title:""} ${data&&data.nfttype==0?"Music Royalty NFT":"PFP NFT"}`).length > 10 ?
						(<div className={styles.title}>
							<div>{`[${data?data.artist_eng:""}] ${data?data.title:""} ${data&&data.nfttype==0?"Music Royalty NFT":"PFP NFT"}`}&nbsp;&nbsp;</div>
							<div>{`[${data?data.artist_eng:""}] ${data?data.title:""} ${data&&data.nfttype==0?"Music Royalty NFT":"PFP NFT"}`}&nbsp;&nbsp;</div>
						</div>):
						(<div className={styles.titleNoLoop}>{`[${data?data.artist_eng:""}] ${data?data.title:""} ${data&&data.nfttype==0?"Music Royalty NFT":"PFP NFT"}`}</div>)
					}
					<div>
						<div className={styles.dataTitle}>{`${new Intl.NumberFormat('ko-KR').format(0)} USD`}</div>
						<div className={styles.subTitle}>Amount Raised</div>
					</div>
					<div>
						<div className={styles.dataTitle}>{`${formatNumber(data?data.ytm_1:0)}%`}</div>
						<div className={styles.subTitle}>Expected Returns</div>
					</div>
					<div className={styles.btns}>
						<a className={styles.btnBg2}>
							<div className={styles.btn1} onClick={() => setClicked(!clicked)}>Play Music</div>
						</a>
						<div className={styles.empty}></div>
						<a className={styles.btnBg}>
							<div className={styles.btn2} onClick={() => setClicked2(!clicked2)}>More <img src={dots} /></div>
							<div className={clicked2?styles.shareBoxHover:styles.shareBox}>
								<div className={styles.shareBoxElem}><img src={guide} /> Buy Guide</div>
								<div className={styles.shareBoxElem}><img src={exchange} /> Royalty Guide</div>
								<CopyToClipboard onCopy={() => {
									toast.success("Share link copied to clipboard!", {
										position: "bottom-right",
										autoClose: 5000,
										hideProgressBar: false,
										closeOnClick: true,
										pauseOnHover: true,
										draggable: true,
										progress: undefined,
										theme: "dark",
									});
									setClicked(!clicked)
								}} text={"https://fanft.land/invest?pid="+props.pid}>
									<div className={styles.shareBoxElem}><img src={icurl} /> URL</div>
								</CopyToClipboard>
								<div className={styles.shareBoxElem} onClick={() => {
									window.open(`
										https://twitter.com/intent/tweet?text=Explore%20the%20opportunity%20to%20invest%20in%20${data.title}%20performed%20by%20${data.eng}%20via%20%40join_fanft%2C%20where%20you%20can%20earn%20royalties%20based%20on%20the%20music%27s%20streaming%20activity.%20Don%27t%20miss%20out%2C%20take%20a%20look%21%20%0Ahttps%3A%2F%2Ffanft.land%2Finvest%3Fpid%3D${props.pid}
									`, "_blank")
									setClicked(!clicked)
								}}><img src={twitter} /> Twitter</div>
							</div>
						</a>
						
					</div>
				</div>
			</div>
		</div>
	)
}

export default TopBanner;