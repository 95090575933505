import React, { useState } from "react";
import styles from "./Timer.module.scss";
// Mr. Aiden, I'm not fan of reinventing :/
import Countdown from "react-countdown"; 


const renderer = ({ days, hours, minutes, seconds }) => {
	// Render a countdown
	return (
		<div className={styles.times}>
			<div className={styles.timeBox}>
				<div className={styles.noMargin}>{new Intl.NumberFormat("en", {minimumIntegerDigits: 2, maximumFractionDigits: 0, minimumFractionDigits: 0}).format(days)}</div>
				<div className={styles.noMargin}>days</div>
			</div>
			<div className={styles.colons}>
				<div>:</div>
			</div>
			<div className={styles.timeBox}>
				<div>{new Intl.NumberFormat("en", {minimumIntegerDigits: 2, maximumFractionDigits: 0, minimumFractionDigits: 0}).format(hours)}</div>
				<div>hr</div>
			</div>
			<div className={styles.colons}>
				<div>:</div>
			</div>
			<div className={styles.timeBox}>
				<div>{new Intl.NumberFormat("en", {minimumIntegerDigits: 2, maximumFractionDigits: 0, minimumFractionDigits: 0}).format(minutes)}</div>
				<div>min</div>
			</div>
			<div className={styles.colons}>
				<div>:</div>
			</div>
			<div className={styles.timeBox}>
				<div className={styles.noMargin}>{new Intl.NumberFormat("en", {minimumIntegerDigits: 2, maximumFractionDigits: 0, minimumFractionDigits: 0}).format(seconds)}</div>
				<div className={styles.noMargin}>sec</div>
			</div>
		</div>
	)
};

const renderer3 = ({ days, hours, minutes, seconds }) => {
	// Render a countdown
	return (
		<div className={styles.times} style={{color: "#f6f6f8"}}>
			<div className={styles.timeBox}>
				<div className={styles.noMargin} style={{color: "#f6f6f8"}}>{new Intl.NumberFormat("en", {minimumIntegerDigits: 2, maximumFractionDigits: 0, minimumFractionDigits: 0}).format(days)}</div>
				<div className={styles.noMargin} style={{color: "#f6f6f8"}}>days</div>
			</div>
			<div className={styles.colons}>
				<div>:</div>
			</div>
			<div className={styles.timeBox}>
				<div style={{color: "#f6f6f8"}}>{new Intl.NumberFormat("en", {minimumIntegerDigits: 2, maximumFractionDigits: 0, minimumFractionDigits: 0}).format(hours)}</div>
				<div style={{color: "#f6f6f8"}}>hr</div>
			</div>
			<div className={styles.colons}>
				<div>:</div>
			</div>
			<div className={styles.timeBox}>
				<div style={{color: "#f6f6f8"}}>{new Intl.NumberFormat("en", {minimumIntegerDigits: 2, maximumFractionDigits: 0, minimumFractionDigits: 0}).format(minutes)}</div>
				<div style={{color: "#f6f6f8"}}>min</div>
			</div>
			<div className={styles.colons}>
				<div>:</div>
			</div>
			<div className={styles.timeBox}>
				<div className={styles.noMargin} style={{color: "#f6f6f8"}}>{new Intl.NumberFormat("en", {minimumIntegerDigits: 2, maximumFractionDigits: 0, minimumFractionDigits: 0}).format(seconds)}</div>
				<div className={styles.noMargin} style={{color: "#f6f6f8"}}>sec</div>
			</div>
		</div>
	)
};

const renderer2 = ({ days, hours, minutes, seconds }) => {
	// Render a countdown
	return (
		<div className={styles.blue}>
			<span className={styles.grey}>Starts in</span> {`${days}d ${hours}h ${minutes}m ${seconds}s`}
		</div>
	)
};

const renderer4 = ({ days, hours, minutes, seconds }) => {
	// Render a countdown
	return (
		<div className={styles.red}>
			<span className={styles.grey}>Ends in</span> {`${days}d ${hours}h ${minutes}m ${seconds}s`}
		</div>
	)
};

const MiniTimer = (props) => {

	return (<Countdown date={new Date(props.date)} renderer={renderer2} />)
}

const Timer = (props) => {

	return (<Countdown date={new Date(props.date)} key={new Date(props.date).toString()} zeroPadTime={2} renderer={renderer} />)
}

const WhiteTimer = (props) => {

	return (<Countdown date={new Date(props.date)} key={new Date(props.date).toString()} zeroPadTime={2} renderer={renderer3} />)
}

const MiniTimer2 = (props) => {

	return (<Countdown date={new Date(props.date)} renderer={renderer4} />)
}


export { Timer, MiniTimer, WhiteTimer, MiniTimer2 };