import React, { useState } from "react";
import { Mobile, PC } from "../../../styles/MediaQuery";

import { useWeb3Auth } from "../../../services/web3auth";
import { NavLink } from "react-router-dom";

import styles from "./TopBanner.module.scss";

import profile from "../../../assets/images/profile.png";
import copy from "../../../assets/icons/black_copy.svg";
import etherscan from "../../../assets/icons/polygonscan.svg";
import wallet from "../../../assets/icons/wallet.png";

const nickname = "불꽃남자"

const TopBanner = (props) => {
  const { provider, login, logout, getUserInfo, getAccounts } = useWeb3Auth();
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");

  const getAddress = async () => {
    const addr = await getAccounts();
    if(!addr || addr.length < 1) return;
    if(address != addr[0]) {
      setAddress(addr[0]);
    }
  }

  const getUser = async () => {
    const user = await getUserInfo();
    if(!user) return;
    if(email != user.email) {
      setEmail(user.email);
    }
  }

  getAddress();
  getUser();

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.left}>
          <img className={styles.profileImg} src={profile}/>
          <div className={styles.blackBtn}>{`${address.slice(0,6)}...${address.slice(38,42)}`} <img src={copy}/></div>
          <div className={styles.whiteBtn} onClick={(e) => {props.setWallet(true)}}><img src={wallet}/> Wallets</div>
          <div className={styles.whiteBtn} onClick={() => window.open("https://mumbai.polygonscan.com/address/"+address)}><img src={etherscan}/> View on Polygonscan</div>
        </div>
        <div className={styles.right}>
          <div className={styles.nickname}>{`User ${address.slice(0,4)}...${address.slice(40,42)}`}</div>
          <div className={styles.email}>{email}</div>
          {/* <div className={styles.shadowBtn}>개인정보 수정</div> */}
        </div>
      </div>
    </div>
  );
};

export default TopBanner;