import React, { useState, useEffect } from "react";

import { Mobile, PC } from "../../../styles/MediaQuery";
import { NavLink } from "react-router-dom";

import styles from "./TopCollection.module.scss";
import { RedTag, WhiteTag } from "../../../components/Common/NameTag";
import { info } from "../../../services/dummy";
import { createPool } from "@vercel/postgres";

import arrow from "../../../assets/icons/chevron-left-24.svg";

var typeSet = new Set();
var artistSet = new Set();

typeSet.add("All");
artistSet.add("All");

const Collection = (props) => {
	return (
		<NavLink className={styles.cardWrapper} to={"/invest?pid="+props.pid}>
			<div className={styles.collectCard}>
				{props.available==0?(<RedTag className={styles.nameTag} upText={props.type} underText="Sold Out" />):
				(<WhiteTag className={styles.nameTag} upText={props.type} underText={`${props.available} / ${props.total} Available NFTs`} />)}
				<div className={styles.coverWrapper}>
					<img src={props.cover} className={styles.collectionCover}/>
				</div>
				<div className={styles.collectDetail}>
					<div className={styles.edition}>{`${props.artist} (${props.eng})`}</div>
					{
						(`${props.title} ${props.type}`).length < 17?
							<div className={styles.project}>{`${props.title} ${props.type}`}</div>:
							<div className={styles.projectLoop}>
								<div>{`${props.title} ${props.type}`} &nbsp;</div>
								<div>{`${props.title} ${props.type}`}</div>
							</div>
					}
					<div className={styles.underPart}>
						<div>
							<div className={styles.titleLeft}>Min Price</div>
							<div className={styles.minPrice}>{`${props.min_price} ${props.currency}`}</div>
						</div>
						<div>
							<div className={styles.titleRight}>Yield to Maturity</div>
							<div className={styles.ror}>{`${props.ror}% (${props.period+"M"})`}</div>
						</div>
					</div>
				</div>
			</div>
		</NavLink>
	);
};

const total = info.length;

const TopCollection = () => {
	const [filter, setFilter] = useState(8);
	const [collectionType, setCollectionType] = useState({All: true, count: 0});
	const [artist, setArtist] = useState({All: true, count: 0});
	const [datum, setDatum] = useState([]);

	function formatNumber (num) {
		return new Intl.NumberFormat('ko-KR', {maximumFractionDigits: 2}).format(num);
	}

	function monthDiff(d1, d2) {
		var months;
		months = (d2.getFullYear() - d1.getFullYear()) * 12;
		months -= d1.getMonth();
		months += d2.getMonth();
		return months <= 0 ? 0 : months;
	}

	const getInfo = async () => {
		const pool = createPool({
			connectionString: process.env.REACT_APP_POSTGRES_URL,
		})
		const { rows } = await pool.sql`SELECT * from "FANFT_Products"`; 
		return rows;
	}

	useEffect(() => {
		getInfo().then((res) => {
			if (datum.length == 0) setDatum(res);
		});
	}, []);

	for(var i=0; i<datum.length; i++) {
		typeSet.add(datum[i].nfttype==0?"Music Royalty NFT":"PFP NFT");
		artistSet.add(`${datum[i].artist} (${datum[i].artist_eng})`);
	}
	const typeList = Array.from(typeSet);
	const artistList = Array.from(artistSet);

	return (
		<div className={styles.topCollectionWrapper}>
			<div className={styles.titleWrapper}>
				<div className={styles.title}>FANFT Collections</div>
			</div>
			<div className={styles.collectionTypeWrapper}>
				<div className={styles.filterTitle}>Collecction Type</div>
				<div className={styles.filterList}>
					{typeList.map((elem, idx) => (
						<div key={idx} 
							onClick={(e) => idx==0?
								setCollectionType({All: true, count: 0}):
								setCollectionType(collectionType.hasOwnProperty(elem)?
									{...collectionType, [elem]: !collectionType[elem], count: collectionType[elem]?collectionType.count-1:collectionType.count+1}:
									{...collectionType, [elem]: true, count: collectionType.count+1}
								)
							} className={
								idx==0&&collectionType.count!=0?styles.filterBtn:(collectionType.hasOwnProperty(elem)&&collectionType[elem]?styles.filterBtnActive:styles.filterBtn)
							} 
						>
						{elem}</div>
					))}
				</div>
			</div>
			<div className={styles.collectionTypeWrapperBottom} >
				<div className={styles.filterTitleSec}>Artist (Edition)</div>
				<div className={styles.filterList}>
					{artistList.map((elem, idx) => (
						<div key={idx} 
							onClick={(e) => idx==0?
								setArtist({All: true, count: 0}):
								setArtist(artist.hasOwnProperty(elem)?
									{...artist, [elem]: !artist[elem], count: artist[elem]?artist.count-1:artist.count+1}:
									{...artist, [elem]: true, count: artist.count+1}
								)
							} className={
								idx==0&&artist.count!=0?styles.filterBtn:(artist.hasOwnProperty(elem)&&artist[elem]?styles.filterBtnActive:styles.filterBtn)
							} 
						>
						{elem}</div>
					))}
				</div>
			</div>
			<div className={styles.collectionsWrapper}>
			{datum.slice(0,filter).map((elem, idx) => (
					(artist["All"] && artist.count == 0) || artist.hasOwnProperty(`${elem.artist} (${elem.artist_eng})`) && artist[`${elem.artist} (${elem.artist_eng})`]?
						(collectionType["All"] && collectionType.count == 0) || collectionType.hasOwnProperty(elem.nfttype==0?"Music Royalty NFT":"PFP NFT") && collectionType[elem.nfttype==0?"Music Royalty NFT":"PFP NFT"]?
						(<Collection 
							pid={elem.pid}
							type={elem.nfttype==0?"Music Royalty NFT":"PFP NFT"}
							available={elem.available}
							total={elem.supply}
							cover={elem.cover}
							artist={elem.artist}
							eng={elem.artist_eng}
							title={elem.title}
							ror={formatNumber(elem.ytm_1)}
							min_price={formatNumber(elem.min_price)}
							currency={"USD"}
							period={monthDiff(new Date(elem.term_start),new Date(elem.term_end))}
						/>):<></>:<></>
				))}
			</div>
			{
				total>filter?(<div className={styles.moreBtn} onClick={(e) => {setFilter(filter + 8)}}>View More</div>):(<div className={styles.blank}></div>)
			}
		</div>
	);
};

export default TopCollection;