import React, { useState, useEffect, useCallback } from "react";

import { Mobile, PC } from "../../../styles/MediaQuery";
import { NavLink } from "react-router-dom";

import uparrow from "../../../assets/images/uparrow.png";
import downarrow from "../../../assets/images/downarrow.png";
import plusbtn from "../../../assets/images/ic_plusbtn.png";

import styles from "./Faq.module.scss";


const contents = [
    {
        "title": "What is the FANFT service?",
        "contents": 
            `It's an NFT-based music revenue investment platform that offers an environment 
            to invest in various music projects like new releases by artists, OSTs, and remade tracks.
            It allows artists and music producers to raise funds through investment without taking on debt, 
            aiming to establish a sustainable music industry ecosystem.`
    },
    {
        "title": "How can I invest?",
        "contents": `You can invest through the purchase of Music Royalty NFTs. 
            These NFTs include a stake that allows you to receive a portion of the song's earnings 
            for the investment duration. It's an investment product where you invest in a track and 
            receive monthly royalty settlements from that track throughout the investment period.`
    },
    {
        "title": "What kind of music can I invest in?",
        "contents": `FANFT collaborates with Korean music producers to carry out music investment projects. 
            The target tracks for these investment projects are new releases within the past 3 months. 
            You have the opportunity to invest in a variety of music projects, including K-POP tracks, 
            drama OSTs, and remade songs.`
    },
    {
        "title": "How can I receive music royalty earnings?",
        "contents": `The distribution revenue of the track is settled monthly, 
            and you can receive a payout based on your ownership stake in the earnings.
            In MyPage, by accessing the specific music project, you can claim 
            your music royalties in USDC via the 'Claim' button.`
    }
]

const Faq = () => {
    const [open, setOpen] = useState([false,false,false,false]);
	return (
		<div className={styles.wrapper}>
			<div className={styles.subtitleDiv}>
				<div className={styles.subtitleEng}>FAQ</div>
				<div className={styles.subtitleKr}>Frequently Asked Questions</div>
			</div>
            {
                contents.map((c, i) => (
                    <div className={styles.barContainer} key={i}>
                        <div className={styles.leftSide}>
                            <img className={styles.btn} src={plusbtn} onClick={(e) => {setOpen(open.map((c, idx) => { if (i === idx) return !c;}))}} style={open[i]?{transform: "rotate(45deg)"}:{}} />
                        </div>
                        <div className={styles.rightSide}>
                            <div className={styles.title} 
                                onClick={(e) => {setOpen(open.map((c, idx) => { if (i === idx) return !c;}))}}>
                                {c.title}
                            </div>
                            <div className={open[i]?styles.content:styles.contentHidden}>{c.contents}</div>
                        </div>
                    </div>))
            }
		</div>
	);
};


export default Faq;