import React from "react";
import styles from "./Footer.module.scss";

import youtube from "../../../../assets/images/youtube.png";
import facebook from "../../../../assets/images/facebook.png";
import instagram from "../../../../assets/images/instagram.png";
import twitter from "../../../../assets/images/twitter.png";
import logow from "../../../../assets/images/logo_white.png";


const Footer = () => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.btnWrapper}>
				<img src={youtube} />
				<img src={facebook} />
				<img src={instagram} />
				<img src={twitter} />
			</div>
			<div className={styles.copyright}>2023 FANFT.  ALL RIGHT RESERVED. V1.22.1</div>
			<img src={logow} className={styles.logow} />
		</div>
	);
};

export default Footer;