import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";
import TopBanner from "./TopBanner/TopBanner";
import Indicators from "./Indicators/Indicators";
import Featured from "./Featured/Featured";
import TabBanner from "./TabBanner/TabBanner";
import TopCollection from "./TopCollection/TopCollection";
import Discord from "./Discord/Discord";
import Faq from "./Faq/Faq";
import FlowBanner from "./FlowBanner/FlowBanner";
import Footer from "./Footer/Footer";


const MobileMainPage = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
    if(document.documentElement.clientWidth >= 1080) {
      window.location.href = "/"
    }
    else {
      props.setLocation("mobile")
    }
  }, [])
  
  return (
    <div style={{maxWidth: 1080, overflow: "hidden"}}>
      <TopBanner />
      <Indicators />
      <Featured />
      <TabBanner />
      <TopCollection />
      <Discord />
      <Faq/>
      <FlowBanner />
      <Footer />
    </div>
  );
};

export default MobileMainPage;
