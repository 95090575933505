import React, { useState } from "react";
import { Mobile, PC } from "../../../styles/MediaQuery";

import styles from "./ProjectInfo.module.scss";

import pessimistic from "../../../assets/images/pessimistic.png";
import probable from "../../../assets/images/probable.png";
import optimistic from "../../../assets/images/optimistic.png";

const ProjectInfo = (props) => {
	const data = props.data;

	function formatNumber (num) {
		return new Intl.NumberFormat('ko-KR', {maximumFractionDigits: 2}).format(num);
	}

	function monthDiff(d1, d2) {
		var months;
		months = (d2.getFullYear() - d1.getFullYear()) * 12;
		months -= d1.getMonth();
		months += d2.getMonth();
		return months <= 0 ? 0 : months;
	}

	return (
		<div>
			<div className={styles.title}>Invest Info</div>
			<div className={styles.subtitleDiv}>
				<div className={styles.subtitleEng}>Project Size</div>
				{/* <div className={styles.subtitleKr}>프로젝트 규모</div> */}
			</div>
			<div className={styles.box}>
				<div className={styles.boxElem}>
					<div className={styles.boxElemTitle}>TOTAL TOKENS</div>
					<div className={styles.boxElemValue}>{data?data.supply:0}</div>
					<div className={styles.boxElemSub}>{3} TIERS</div>
				</div>
				<div className={styles.line}></div>
				<div className={styles.boxElem}>
					<div className={styles.boxElemTitle}>TOTAL OWNERSHIP</div>
					<div className={styles.boxElemValue}>{data?formatNumber(data.royalty):0}%</div>
					<div className={styles.boxElemSub}>STREAMING ROYALTY</div>
				</div>
				<div className={styles.line}></div>
				<div className={styles.boxElem}>
					<div className={styles.boxElemTitle}>AMOUNT RAISED</div>
					<div className={styles.boxElemValue}>{new Intl.NumberFormat('ko-KR').format(0)} USD</div>
					<div className={styles.boxElemSub}>USDC</div>
				</div>
			</div>
			<div className={styles.subtitleDiv} style={{marginTop: "60px"}}>
				<div className={styles.subtitleEng}>Project Yield to Maturity ({data?monthDiff(new Date(data.term_start),new Date(data.term_end)):0} Month)</div>
				{/* <div className={styles.subtitleKr}>프로젝트 예상 수익률 ({data.period < 13? `${data.period} 개월` : `${(data.period/12)%1==0?(data.period/12).toFixed(0):(data.period/12).toFixed(2)} 개년`})</div> */}
			</div>
			<div className={styles.ytmBox}>
				<div>
					<div className={styles.ytmTop}>
						<img src={pessimistic} className={styles.ytmImg} />
						<div className={styles.ytmTitle}>pessimistic</div>
					</div>
					<div className={styles.ytmValue}>{formatNumber(data?data.ytm_0:0)}%</div>
				</div>
				<div>
					<div className={styles.ytmTop}>
						<img src={probable} className={styles.ytmImg}/>
						<div className={styles.ytmTitle}>probable</div>
					</div>
					<div className={styles.ytmValue}>{formatNumber(data?data.ytm_1:0)}%</div>
				</div>
				<div>
					<div className={styles.ytmTop}>
						<img src={optimistic} className={styles.ytmImg} />
						<div className={styles.ytmTitle}>optimistic</div>
					</div>
					<div className={styles.ytmValue}>{formatNumber(data?data.ytm_2:0)}%</div>
				</div>
			</div>
		</div>
	);
};

export default ProjectInfo;