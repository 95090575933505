import React, { useState } from "react";
import styles from "./Card.module.scss";
import { Mobile, PC } from "../../styles/MediaQuery";
import EtherIcon from "../../assets/images/etherIcon.png";
import KlayIcon from "../../assets/images/klayIcon.png";
import OpenseaIcon from "../../assets/images/OpenSea-Full-Logo.svg";
import OpenseaDarkIcon from "../../assets/images/openseaDark.png";
import PolygonIcon from "../../assets/icons/bitmap-copy-19@3x.webp"
import OpenseaWhite from "../../assets/icons/opensea_white.svg"

const ArtistCard = (props) => {
	return (
		<div className={styles.artistContainer} style={{transform: `scale(${props.isMobile?0.75:1})`}}>
			<div className={styles.artistInner} style={{backgroundImage: `url(${props.src})`}}>
				<div className={styles.artistNameBox}>
					<div className={styles.artistNameBoxUpper}>{props.name}</div>
					<div className={styles.artistNameBoxLower}>{props.eng}</div>
				</div>
			</div>
		</div>
	)
}

const ArtistCardNoKor = (props) => {
	return (
		<div className={styles.artistContainer} style={{transform: `scale(${props.isMobile?0.75:1})`}}>
			<div className={styles.artistInner} style={{backgroundImage: `url(${props.src})`}}>
				<div className={styles.artistNameBox}>
					<div className={styles.artistNameBoxLower}>{props.eng}</div>
				</div>
			</div>
		</div>
	)
}

const WideCard = (props) => {
	return (
		<div className={styles.wideContainer}>
			<div className={styles.imgContainer}>
				<img src={props.img} />
			</div>
			<div className={styles.remainContainer}>마감까지 {props.remains}일</div>
			<div className={styles.textContainer}>
				<div className={styles.artist}>{props.artist}</div>
				<div className={styles.details}>
					{props.details}
				</div>
			</div>
		</div>
	);
}

const SquareCard = (props) => {
	return (
		<div className={styles.squareContainer} style={{
			backgroundImage: props.src
		}}>
			<div className={styles.ticker}>
				{props.noCrypto?(<div></div>):(<img src={props.isEth?EtherIcon:KlayIcon} />)}
			</div>
			<div className={styles.albumInfo}>
				<div className={styles.title}>{props.title}</div>
				<div className={styles.under}>
					<div className={styles.artistSquare}>{props.artist}</div>
					{ props.noCrypto?
						(<div></div>):
						(props.isCollect?
							(<div className={styles.collectBtn}><div className={styles.mobText}>Collect</div></div>):
							(<div className={styles.openseaBtn}><img src={OpenseaIcon} /></div>))
					}
				</div>
			</div>
		</div>
	);
}

const ShowCard = (props) => {
	return (
		<div className={styles.showContainter}>
			<img className={styles.showAlbum} src={props.src} />
			<div className={styles.showDate}>{props.date}</div>
			<div className={styles.showTitle}>{props.title}</div>
			<div className={styles.showContent}>{props.loc}</div>
		</div>
	);
} 

const GoodsCard = (props) => {
	return (
		<div className={styles.showContainter}>
			<img className={styles.showAlbum}  src={props.src} />
			<div className={styles.showDate}>{props.upper}</div>
			<div className={styles.showTitle}>{props.title}</div>
			<div className={styles.goodsContent}>{props.lower}</div>
		</div>
	);
} 

const NFTCard = (props) => {
	return (
		<div className={styles.nftContainter}>
			<img className={styles.showAlbum}  src={props.src} />
			<div className={styles.wrapper}><img className={styles.NFTticker} src={props.isEth?EtherIcon:KlayIcon} /></div>
			<div className={styles.showDate}>{props.upper}</div>
			<div className={styles.nftTitle}>{props.title}</div>
			<div className={styles.nftUnder}> 
				<div className={styles.nftContent}>{props.lower}</div>
				{ props.isCollect?
					(<div className={styles.nftCollectBtn}><div className={styles.scale}>Collect</div></div>):
					(<div className={styles.nftOpenseaBtn}><img src={OpenseaDarkIcon} /></div>)
				}
			</div>
		</div>
	);
} 

const NFTDetailCard = (props) => {
	return (
		<div>
			<div className={styles.detailContainer}>
				<div className={styles.albumWrapper}>
					<img src={props.img} className={styles.albumImg} />
					<img className={styles.chainIcon} src={KlayIcon} />
				</div>
				<div className={styles.rankWrapper}>
					<div className={styles.rankLeft}>Normal 등급</div>
					<div className={styles.rankRight}>100개/ 총 200개</div>
				</div>
				<div className={styles.detailWrapper}>
					<div className={styles.artistDetail}>FANFT & 헤이즈</div>
					<div className={styles.artistTitle}>O.D.R  PROJECT  Part : 7 NFT Music Album</div>
					<div className={styles.ownershipInfo}><div className={styles.percent}>10.79%</div><div className={styles.tokenType}>OWNERSHIP TOKEN</div></div>
					<div className={styles.line}></div>
					<dl className={styles.listWrapper}>
						<dt>음원수입의 0.7%를 매월 ‘FAN Point’의 형태로 정산 됩니다. (현금화, 굿즈구입, 공연예약 가능)</dt>
						<dd>FANFT 공연 패스권 3매 제공</dd>
						<dd>팬미팅 초대 및 한정수량 굿즈 제공</dd>
						<dd>NFT홀더 한정수량 실물 엘범 제공</dd>
						<dd>FANFT collector 디스코드 초대</dd>
					</dl>
					
					<div className={styles.readMoreBtn}>READ MORE</div>
					<div className={styles.priceWrapper}><div>Price</div> <img className={styles.priceIcon} src={PolygonIcon} /> <div className={styles.price}>19,000</div> <div>(0.02 ETH)</div></div>
					{props.mintable?(<div className={styles.detailBtn}>Collect</div>):(<div className={styles.detailBtnBlack}><img src={OpenseaWhite} /> Collect on Opensea</div>)}
				</div>
			</div>
			<div className={styles.sellAmount}>1,000 TOKENS</div>
		</div>
	);
}

export { WideCard, ArtistCard, ArtistCardNoKor, SquareCard, ShowCard, GoodsCard, NFTCard, NFTDetailCard };