import React, { useState, useEffect } from "react";
import { Mobile, PC } from "../../styles/MediaQuery";

import FlowBanner from "../main/FlowBanner/FlowBanner";
import Footer from "../main/Footer/Footer";
import Guide from "./Guide/Guide";

const Guidepage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <>
      <Guide />
      <FlowBanner />
      <Footer />
    </>
  );
};

export default Guidepage;