import "./App.scss";

// Renewaled Good Code
import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { Web3AuthProvider } from "./services/web3auth";
import { Mobile, PC } from "./styles/MediaQuery";
import Header from "./components/Header/Header";
import Mainpage from "./pages/main/Mainpage";
import Guidepage from "./pages/guide/Guidepage";
import Collectionpage from "./pages/collection/Collectionpage";
import Investpage from "./pages/invest/Investpage"
import Mypage from "./pages/mypage2/Mypage"
import MobileMainPage from "./pages/mobile/main/Mainpage";

// Fucking Trash
import NavBar from "./components/NavBar/NavBar";
import { MintPageMain } from "./pages/MintPage/MintPageMain";
import { Login } from "./pages/login/Login";
import MusicPlayer from "./components/MusicPlayer/MusicPlayer";


function App() {
  const [current, setCurrent] = useState(0);
  const [location, setLocation] = useState("");

  return (
    <div className="App">
      <Web3AuthProvider chain={"ethereum"} web3AuthNetwork={"testnet"}>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/mobile" element={<MobileMainPage setLocation={setLocation} />}>
            {/* <Route path="/mobile/pika" element={<Guidepage />} /> */}
          </Route>
          <Route path="/" element={<Mainpage />} />
          <Route path="/guide" element={<Guidepage />} />
          <Route path="/collection" element={<Collectionpage />} />
          <Route path="/invest" element={<Investpage setCurrent={setCurrent} />} />
          <Route path="/mypage" element={<Mypage />} />
          <Route path="/login" element={<Login />} />
        </Routes>
        {location.includes("mobile")?(<></>):(<MusicPlayer current={current} setCurrent={setCurrent} />)}
      </BrowserRouter>
      </Web3AuthProvider>
    </div>
  );
}

export default App;
