import cover from "../assets/images/cover.png";
import cover2 from "../assets/images/cover2.jpeg";
import cover3 from "../assets/images/cover3.jpeg";
import cover4 from "../assets/images/cover4.jpg";
import cover5 from "../assets/images/cover5.jpg";
import cover6 from "../assets/images/cover6.jpg";
import cover7 from "../assets/images/cover7.jpg";
import cover8 from "../assets/images/cover8.jpeg";

import over from "../assets/images/over.png";
import getup from "../assets/images/getup.png";
import lovesong from "../assets/images/lovesong.png";

import party from "../assets/images/party.png";
import ticket from "../assets/images/ticket.png";
import oneday from "../assets/images/class.png";
import goods from "../assets/images/goods.png";
import meeting from "../assets/images/meeting.png";
import community from "../assets/images/community.png";
import community2 from "../assets/images/community2.png";
import dinner from "../assets/images/dinner.png";

import normal from "../assets/lotties/grade_icon_normal.mp4";
import fan from "../assets/lotties/grade_icon_fan.mp4";
import vip from "../assets/lotties/grade_icon_vip.mp4";
import vvip from "../assets/lotties/grade_icon_vvip.mp4";

import ic1 from "../assets/images/ic1.png";
import ic2 from "../assets/images/ic2.png";
import ic3 from "../assets/images/ic3.png";
import ic4 from "../assets/images/ic4.png";
import ic5 from "../assets/images/ic5.png";
import ic6 from "../assets/images/ic6.png";
import ic7 from "../assets/images/ic7.png";
import ic8 from "../assets/images/ic8.png";

import profile from "../assets/images/profile.png";

const benefits = [{
	id: 0,
	name: "Party Invitation",
	img: ic7,
	detail: "Party Invitation grants you access to the party you selected or the party hosted by the artist. More detailed usage instructions will be provided exclusively to ones who invited. You can check your invitations in mypage",
	amt: "Quantity: 1 piece",
	subtext: "Available"
},
{
	id: 1,
	name: "Concert ticket",
	img: ic4,
	detail: "Concert tickets and available concert dates will be distributed to users after the minting period. You can check your tickets in mypage",
	amt: "수량 : 1매",
	subtext: "Available"
},
{
	id: 2,
	name: "One day class",
	img: ic1,
	detail: "해당 파티 초대권은 상품 구매와 동시에 구매 계정의 [마이페이지>보유 티켓]에서 확인할 수 있으며,  사용자가 선택한 파티 또는 아티스트가 개최하는 파티에 참여할 수 있는 권한 입니다. 프로젝트 기간에 1번 사용 가능 하고 조금 더 자세한 사용 방법은 상품 보유자에 한하여 공지드릴 예정 입니다.",
	amt: "수량 : 1매",
	subtext: "Comming Soon"
},
{
	id: 3,
	name: "굿즈 제공",
	img: ic2,
	detail: "해당 파티 초대권은 상품 구매와 동시에 구매 계정의 [마이페이지>보유 티켓]에서 확인할 수 있으며,  사용자가 선택한 파티 또는 아티스트가 개최하는 파티에 참여할 수 있는 권한 입니다. 프로젝트 기간에 1번 사용 가능 하고 조금 더 자세한 사용 방법은 상품 보유자에 한하여 공지드릴 예정 입니다.",
	amt: "수량 : 1매",
	subtext: "Available"
},
{
	id: 4,
	name: "팬미팅 초대권",
	img: ic7,
	detail: "해당 파티 초대권은 상품 구매와 동시에 구매 계정의 [마이페이지>보유 티켓]에서 확인할 수 있으며,  사용자가 선택한 파티 또는 아티스트가 개최하는 파티에 참여할 수 있는 권한 입니다. 프로젝트 기간에 1번 사용 가능 하고 조금 더 자세한 사용 방법은 상품 보유자에 한하여 공지드릴 예정 입니다.",
	amt: "수량 : 1매",
	subtext: "Comming Soon"
},
{
	id: 5,
	name: "아티스트 커뮤니티",
	img: ic6,
	detail: "해당 파티 초대권은 상품 구매와 동시에 구매 계정의 [마이페이지>보유 티켓]에서 확인할 수 있으며,  사용자가 선택한 파티 또는 아티스트가 개최하는 파티에 참여할 수 있는 권한 입니다. 프로젝트 기간에 1번 사용 가능 하고 조금 더 자세한 사용 방법은 상품 보유자에 한하여 공지드릴 예정 입니다.",
	amt: "수량 : 1매",
	subtext: "Comming Soon"
},
{
	id: 6,
	name: "Community invitation",
	img: ic5,
	detail: "The invitation to the community is exclusively available to investors of this project. Community tickets will be distributed to users after the minting period. You can check your tickets in mypage",
	amt: "Quantity: 1 piece",
	subtext: "Comming Soon"
},
{
	id: 7,
	name: "식사 초대",
	img: ic8,
	detail: "해당 파티 초대권은 상품 구매와 동시에 구매 계정의 [마이페이지>보유 티켓]에서 확인할 수 있으며,  사용자가 선택한 파티 또는 아티스트가 개최하는 파티에 참여할 수 있는 권한 입니다. 프로젝트 기간에 1번 사용 가능 하고 조금 더 자세한 사용 방법은 상품 보유자에 한하여 공지드릴 예정 입니다.",
	amt: "수량 : 1매",
	subtext: "Comming Soon"
},
]

const info = [{
	pid: 1,
	artist: "김예준",
	eng: "Kim Ye Joon",
	title: "Over",
	type: "Music Royalty NFT",
	date: "2023-08-31 12:00:00",
	chain: "Polygon Mainnet",
	contract: "0xA0007cCA4a6f2779d0A85E10A34B4354DBe70ac9",
	legal: "https://www.sec.gov/reportspubs/investor-publications/investorpubsinvclubhtm.html",
	cover: over,
	available: 92,
	total: 92,
	period: 16,
	royalty: 20,
	total_price: 0,
	ytm: [18.03, 24.48, 32.17],
	currency: "USD",
	detail: {
		name: "Over",
		genre: "R&B/Soul",
		releaseDate: "2023.09.01",
		isrc: "",
		lyricsBy: "Kim Ye Joon",
		composedBy: "Kim Ye Joon, Fire Man, Poly",
		produceDetail: "Produced by Fire Man, Poly\nVocal arranged by Kim Ye Joon\nChorus by Kim Ye Joon",
	},
	tier: [ 
		{
			name: "Normal",
			badge: normal,
			price: 20.0,
			benefit: [6],
			total: 90,
			mintStarts: "2023-08-31 12:00:00",
			mintEnds: "2023-09-25 12:00:00",
			available: 90,
			royalty: 0.2

		},
		{
			name: "FAN",
			badge: fan,
			price: 50.0,
			total: 1,
			benefit: [1,6],
			mintStarts: "2023-08-31 12:00:00",
			mintEnds: "2023-09-25 12:00:00",
			available: 1,
			royalty: 0.5

		},
		{
			name: "VVIP",
			badge: vvip,
			price: 150.0,
			total: 1,
			benefit: [1,6,0],
			mintStarts: "2023-08-31 12:00:00",
			mintEnds: "2023-09-25 12:00:00",
			available: 1,
			royalty: 1.5

		}
	]
},
{
	pid: 2,
	artist: "김예준",
	eng: "Kim Ye Joon",
	title: "Get up",
	type: "Music Royalty NFT",
	date: "2023-08-31 12:00:00",
	chain: "Polygon Mainnet",
	contract: "0xA0007cCA4a6f2779d0A85E10A34B4354DBe70ac9",
	legal: "https://www.sec.gov/reportspubs/investor-publications/investorpubsinvclubhtm.html",
	cover: getup,
	available: 92,
	total: 92,
	period: 16,
	royalty: 20,
	total_price: 0,
	ytm: [18.03, 24.48, 32.17],
	currency: "USD",
	detail: {
		name: "Get up",
		genre: "R&B/Soul",
		releaseDate: "2023.09.01",
		isrc: "",
		lyricsBy: "Kim Ye Joon",
		composedBy: "Kim Ye Joon, Fire Man, Poly",
		produceDetail: "Produced by Fire Man, Poly\nVocal arranged by Kim Ye Joon\nChorus by Kim Ye Joon",
	},
	tier: [ 
		{
			name: "Normal",
			badge: normal,
			price: 20.0,
			benefit: [6],
			total: 90,
			mintStarts: "2023-09-25 12:00:00",
			mintEnds: "2023-09-25 12:00:00",
			available: 90,
			royalty: 0.2

		},
		{
			name: "FAN",
			badge: fan,
			price: 50.0,
			total: 1,
			benefit: [1,6],
			mintStarts: "2023-09-25 12:00:00",
			mintEnds: "2023-09-25 12:00:00",
			available: 1,
			royalty: 0.5

		},
		{
			name: "VVIP",
			badge: vvip,
			price: 150.0,
			total: 1,
			benefit: [1,6,0],
			mintStarts: "2023-09-25 12:00:00",
			mintEnds: "2023-09-25 12:00:00",
			available: 1,
			royalty: 1.5

		}
	]
},
{
	pid: 3,
	artist: "신디",
	eng: "Syndy",
	title: "Love Song",
	type: "Music Royalty NFT",
	date: "2023-08-31 12:00:00",
	chain: "Polygon Mainnet",
	contract: "0xA0007cCA4a6f2779d0A85E10A34B4354DBe70ac9",
	legal: "https://www.sec.gov/reportspubs/investor-publications/investorpubsinvclubhtm.html",
	cover: lovesong,
	available: 92,
	total: 92,
	period: 16,
	royalty: 20,
	total_price: 0,
	ytm: [18.03, 24.48, 32.17],
	currency: "USD",
	detail: {
		name: "Get up",
		genre: "R&B/Soul",
		releaseDate: "2023.09.01",
		isrc: "",
		lyricsBy: "Kim Ye Joon",
		composedBy: "Kim Ye Joon, Fire Man, Poly",
		produceDetail: "Produced by Fire Man, Poly\nVocal arranged by Kim Ye Joon\nChorus by Kim Ye Joon",
	},
	tier: [ 
		{
			name: "Normal",
			badge: normal,
			price: 20.0,
			benefit: [6],
			total: 90,
			mintStarts: "2023-08-31 12:00:00",
			mintEnds: "2023-09-12 12:00:00",
			available: 90,
			royalty: 0.2

		},
		{
			name: "FAN",
			badge: fan,
			price: 50.0,
			total: 1,
			benefit: [1,6],
			mintStarts: "2023-08-31 12:00:00",
			mintEnds: "2023-09-12 12:00:00",
			available: 1,
			royalty: 0.5

		},
		{
			name: "VVIP",
			badge: vvip,
			price: 150.0,
			total: 1,
			benefit: [1,6,0],
			mintStarts: "2023-08-31 12:00:00",
			mintEnds: "2023-09-12 12:00:00",
			available: 1,
			royalty: 1.5

		}
	]
}
]

const holders = [
{
	img: profile,
	nickname: "hako.eth",
	address: "0xA0007cCA4a6f2779d0A85E10A34B4354DBe70ac9",
	tier: 1,
	id: 10
},
{
	img: profile,
	nickname: "hako.eth",
	address: "0xA0007cCA4a6f2779d0A85E10A34B4354DBe70ac9",
	tier: 2,
	id: 15
},
{
	img: profile,
	nickname: "hako.eth",
	address: "0xA0007cCA4a6f2779d0A85E10A34B4354DBe70ac9",
	tier: 2,
	id: 12
},
{
	img: profile,
	nickname: "hako.eth",
	address: "0xA0007cCA4a6f2779d0A85E10A34B4354DBe70ac9",
	tier: 0,
	id: 17
},
{
	img: profile,
	nickname: "hako.eth",
	address: "0xA0007cCA4a6f2779d0A85E10A34B4354DBe70ac9",
	tier: 1,
	id: 19
},
{
	img: profile,
	nickname: "hako.eth",
	address: "0xA0007cCA4a6f2779d0A85E10A34B4354DBe70ac9",
	tier: 0,
	id: 20
},
{
	img: profile,
	nickname: "hako.eth",
	address: "0xA0007cCA4a6f2779d0A85E10A34B4354DBe70ac9",
	tier: 0,
	id: 21
},
{
	img: profile,
	nickname: "hako.eth",
	address: "0xA0007cCA4a6f2779d0A85E10A34B4354DBe70ac9",
	tier: 1,
	id: 22
},
{
	img: profile,
	nickname: "hako.eth",
	address: "0xA0007cCA4a6f2779d0A85E10A34B4354DBe70ac9",
	tier: 2,
	id: 23
}
]

const userInfo = {
	total: 2310,
	ror: 50.23,
	maturity: 15,
	revenue: 3465,
	invest: [{
		pid:1,
		start: "2022.10.31 12:00:00",
		end: "2025.10.31 12:00:00",
		royalty: 3.25,
		amt: [10,1,1],
		invest: 1329.9,
		expected_ror: 78.2,
		revenue: 132.9,
		current_claim: 2,
		available_claim: 2,
		total_claim:36
	},{
		pid:2,
		start: "2022.10.31 12:00:00",
		end: "2025.10.31 12:00:00",
		royalty: 3.25,
		amt: [10,1,1],
		invest: 1329.9,
		expected_ror: 42.2,
		revenue: 132.9,
		current_claim: 2,
		available_claim: 0,
		total_claim:36
	},{
		pid:3,
		start: "2022.10.31 12:00:00",
		end: "2025.10.31 12:00:00",
		royalty: 3.25,
		amt: [10,1,1],
		invest: 1329.9,
		expected_ror: 32.2,
		revenue: 132.9,
		current_claim: 2,
		available_claim: 1,
		total_claim:36
	}]
}

export { info, benefits, holders, userInfo }