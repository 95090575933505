import React, { useState } from "react";

import { Mobile, PC } from "../../../styles/MediaQuery";
import { NavLink } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import styles from "./ShoutOut.module.scss";
import { RedTag, BlueTag, BlueBlackTag } from "../../../components/Common/NameTag";
import arrow from "../../../assets/icons/chevron-left-24.svg";
import whitearrow from "../../../assets/icons/chevron-left-24-w.svg";
import playBtn from "../../../assets/icons/group-10-copy-5.svg";
import shoutout from "../../../assets/images/shoutout.png";

import discord from "../../../assets/images/join_discord.png";

// Toast for test
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const info = [
	{
		artist: "소각소각",
		eng: "SOGAK SOGAK"
	},
	{
		artist: "소각소각",
		eng: "SOGAK SOGAK"
	},
	{
		artist: "소각소각",
		eng: "SOGAK SOGAK"
	},
	{
		artist: "소각소각",
		eng: "SOGAK SOGAK"
	},
	{
		artist: "소각소각",
		eng: "SOGAK SOGAK"
	},
	{
		artist: "소각소각",
		eng: "SOGAK SOGAK"
	},
	{
		artist: "소각소각",
		eng: "SOGAK SOGAK"
	}
]

const SupporterCard = (props) => {
	return (
		<div className={styles.cardWrapper}>
			<BlueTag upText={"참여 가능"} underText={`${props.artist} (${props.eng})`} />
			<div className={styles.cardTitle}>{props.artist} 신곡 홍보 서포터즈</div>
			<div className={styles.cardContent}>
				서포터즈에 참여해 미션을 모두 완료 하면 해당 프로젝트의 Royalty NFT와 공연 티켓을 드립니다.
			</div>
			<div className={styles.cardDetailWrapper} onClick={() => {toast("사이트가 아파요")}}>
				Read details
				<img src={whitearrow} />
			</div>
		</div>
	)
}

const ShoutOut = () => {
	const [current, setCurrent] = useState(0);
	return (
		<div className={styles.wrapper}>
			<ToastContainer />
			<div className={styles.container}>
				<div className={styles.titleWrapper}>
					<div className={styles.leftSide}>
						<div className={styles.title}>
							Join Our Community<br /> 
							Follow up on the latest news
						</div>
						<div className={styles.subtext}>
							Join our the FANFT community, you can be the first to receive information about investment projects. 
							Get details on investment project opportunities.
						</div>
						<a className={styles.joinBtn} href="https://discord.gg/xRaPcSzT68" target="_blank">
							Join us
						</a>
						{/* <div className={styles.infoWrapper}
							onClick={() => {toast("사이트가 아파요")}}
						>
							<img src={playBtn} width={37} height={37} className={styles.playBtn}/>
							See Infomation Video
						</div> */}
					</div>
					<div className={styles.rightSide}>
						<img src={discord} width={373} height={373} />
					</div>
				</div>
				{/*<div className={styles.supporterTitle}>
					<div className={styles.sTitle}>Supporter 모집</div>
					<div className={styles.sSub}></div>
				</div>
				<div className={styles.swiperContainer}>
					<Swiper
					  	style={{overflow: "visible", width: "100%"}}
					  	onSlideChange={(swiper) => setCurrent(swiper.realIndex)}
					  	slidesPerView={4}
					  	slidesPerGroup={1}
					  	loop={true}
					  	loopAdditionalSlides={50}
					  	navigation={false}
					  	slideToClickedSlide={false}
					  	spaceBetween={46}
					  	className="mySwiper"
					>
						{info.map((elem) => (
							<SwiperSlide key={elem.pid}>
						  		<SupporterCard artist={info[0].artist} eng={info[0].eng}/>
						  	</SwiperSlide>
						))}
					</Swiper>
				</div> */}
			</div>
		</div>
	);
};

export default ShoutOut;