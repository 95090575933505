import React, { useState,useEffect } from "react";
import { Mobile, PC } from "../../styles/MediaQuery";
import Wallet from "../../components/Wallet/Wallet";

import styles from "./Mypage.module.scss";

import TopBanner from "./TopBanner/TopBanner";
import Portfolio from "./Portfolio/Portfolio";
import NFTList from "./NFTList/NFTList";
import FlowBanner from "../main/FlowBanner/FlowBanner";
import Footer from "../main/Footer/Footer";
import MyTicket from "./MyTicket/MyTicket";
import Transaction from "./Transaction/Transaction";
import ClaimModal from "./ClaimModal/ClaimModal";
import NFTWallet from "../../components/NFTWallet/NFTWallet";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useWeb3Auth } from "../../services/web3auth";

const menu = ["My Portfolio", "My Ticket", "NFT List", "Transactions"]

const Mypage = () => {
  const [current, setCurrent] = useState(menu[0]);
  const [wallet, setWallet] = useState(false);
  const [claim, setClaim] = useState(false);
  const [transfer, setTransfer] = useState(false);
  const { provider, login, logout, getUserInfo, getAccounts } = useWeb3Auth();

  useEffect(() => {
    window.scrollTo(0, 0)
    if(!provider) { 
      window.location.href = "/"
    }
  }, [])

  return (
    <>
      { wallet?(<Wallet setActive={setWallet} active={wallet} />):(<></>) }
      { claim?(<ClaimModal setActive={setClaim} active={claim} />):(<></>) }
      { transfer?(<NFTWallet setActive={setTransfer} active={transfer} />):(<></>) }
      <ToastContainer position="bottom-right" />
      <TopBanner setWallet={setWallet} />
      <div className={styles.tabMenu}>
        <div onClick={(e) => setCurrent(menu[0])} className={current == menu[0] ? styles.menuElemActive : styles.menuElem}>{menu[0]}</div>
        {/* <div onClick={(e) => setCurrent(menu[1])} className={current == menu[1] ? styles.menuElemActive : styles.menuElem}>{menu[1]}</div> */}
        <div onClick={(e) => setCurrent(menu[2])} className={current == menu[2] ? styles.menuElemActive : styles.menuElem}>{menu[2]}</div>
        {/* <div onClick={(e) => setCurrent(menu[3])} className={current == menu[3] ? styles.menuElemActive : styles.menuElem}>{menu[3]}</div> */}
      </div>
      <div className={styles.line}></div>
      { current == menu[0] ? (<Portfolio setActive={setClaim} />):(<></>)}
      { current == menu[1] ? (<MyTicket />):(<></>)}
      { current == menu[2] ? (<NFTList setActive={setTransfer} />):(<></>)}
      { current == menu[3] ? (<Transaction />):(<></>)}
      <FlowBanner />
      <Footer />
    </>
  );
};

export default Mypage;