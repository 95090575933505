import React from "react";
import styles from "./FlowBanner.module.scss";

import InfiniteSlider from "../../../components/Common/InfiniteSlider";
import logo from "../../../assets/images/logo.png";
import logo2 from "../../../assets/images/logo2.png";
import logo3 from "../../../assets/images/logo3.png";
import logo4 from "../../../assets/images/logo4.png";
import logo5 from "../../../assets/images/logo5.png";

const FlowBanner = () => {
  const imgs =  Array.from({ length: 5 }, () => [
    {
      src: logo2,
      invert: false
    },
    {
      src: logo3,
      invert: true
    },
    {
      src: logo4,
      invert: true
    },
    {
      src: logo5,
      invert: true
    }
  ]).flat();

  return (
    <div className={styles.container}>
      <div className={styles.inner}>
      <InfiniteSlider numElem={
				document.documentElement.clientWidth / 160
			} elems={
				imgs.map((img, idx) => (
					<div className={styles.elemWraper}>
					  <img src={img.src} className={img.invert?styles.invert:styles.noInvert}/>
					</div>
				))
			} />
      </div>
    </div>
  );
};

export default FlowBanner;