import React, { useState } from "react";
import styles from "./Button.module.scss";

import { Mobile, PC } from "../../styles/MediaQuery";


const ToggleButton = (props) => {
	const handleChange = props.onChange
	const btnList = props.btnList?props.btnList:[]
	const selected = btnList[props.value]
	const highlightBackground = props.highlightBg?props.highlightBg:"#ff424d"
	const highlightColor = props.highlightColor?props.highlightColor:"white"
	const nonSelectBackground = props.nonSelectBg?props.nonSelectBg:"#f7f7f7"
	const nonSelectColor = props.nonSelectColor?props.nonSelectColor:"#43434a"
	const border = props.border?props.border:"0px"

	return (
		<div className={styles.toggleBtn}> 
		{props.label?(<div className={styles.label}>{props.label}</div>):(<div></div>)}
		<div className={styles.btnList}>
		{
			btnList.map((name, idx) => (
				<div className={name==selected?styles.selected:styles.nonSelected} name="gender" value={idx} 
					key={idx} onClick={handleChange}
					style={{
						backgroundColor: name==selected?highlightBackground:nonSelectBackground,
						color: name==selected?highlightColor:nonSelectColor,
						border: border
					}}
				>
					{name}
				</div>
			))
		}
		</div>
		</div>
	);
}

const TabButton = (props) => {
	const handleChange = props.onChange
	const btnList = props.btnList?props.btnList:[]
	const selected = btnList[props.value]
	const highlightLine = props.highlightLine?props.highlightLine:"#ff424d"
	const highlightColor = props.highlightColor?props.highlightColor:"#ff424d"
	const nonSelectLine = props.nonSelectLine?props.nonSelectLine:"#f6f6f8"
	const nonSelectColor = props.nonSelectColor?props.nonSelectColor:"#43434a"
	const border = props.border?props.border:"#f6f6f8"
	const isCenter = props.center?props.center:false
	const padding = props.padding?props.padding:0
	const margin = props.margin!=null?props.margin:40

	return (<div className={styles.tabBtn}>
		<div className={styles.btnList} style={isCenter?{justifyContent: "center"}:{}}>
		{
			btnList.map((name, idx) => (
				<div className={name==selected?styles.selected:styles.nonSelected} name={props.name} value={idx} 
					key={idx} onClick={handleChange}
					style={{
						color: name==selected?highlightColor:nonSelectColor,
						borderBottom: "3px "+(name==selected?highlightLine:nonSelectLine)+" solid",
						marginRight: margin,
						paddingLeft: padding,
						paddingRight: padding
					}}
				>
					{name}
				</div>
			))
		}
		</div>
		<div className={styles.bottomLine} style={{backgroundColor: border}}></div>
	</div>)
}

export { ToggleButton, TabButton };